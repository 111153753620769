import useSWR from 'swr';
import axios from 'axios';

const fetcher = ([url, auth_header]) => axios.get(url, { headers: auth_header }).then(res => res.data);

export default function useTransactionHistory(page, status, search, startDate, endDate, auth_header) {
  const { data, error, isValidating, mutate } = useSWR([`/api/practitioner/transactions?page=${page}&start=${startDate}&end=${endDate}`, auth_header], fetcher);

  return {
    transactions: data ? data.transactions : null,
    total_count: data ? data.total_count : null,
    isLoading: !error && !data,
    isError: error,
    mutateTransactionHistory: mutate
  };
}

export async function downloadTransactionCSV(auth_header, startDate, endDate) {
  try {
    const response = await axios.post('/api/practitioner/email-transaction-csv', { start: startDate, end: endDate }, { headers: auth_header });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }

    return { success: true };
  } catch (error) {
    console.error('Error downloading CSV:', error);
    return { success: false, error };
  }
}