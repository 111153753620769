import React from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function Popup({ open, setOpen, success, message }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="tw-relative tw-z-10">
      <DialogBackdrop
        transition
        className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
      />

      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
          <DialogPanel
            transition
            className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6 data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95"
          >
            <div>
              <div className={`tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full ${success ? 'tw-bg-green-100' : 'tw-bg-red-100'}`}>
                {success ? <CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" /> : <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-600" />}
              </div>
              <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                <DialogTitle as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                  {success ? 'Payment successful' : 'Payment failed'}
                </DialogTitle>
                <div className="tw-mt-2">
                  <p className="tw-text-sm tw-text-gray-500">
                    {message}
                  </p>
                </div>
              </div>
            </div>
            {!success && (
              <div className="tw-mt-5 sm:tw-mt-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                >
                  Close
                </button>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}