import React, {useState, useCallback, useEffect} from "react";
import { Combobox } from '@headlessui/react'
import { throttle } from 'lodash';
import { InputLabel, SkeletonInput } from "../components/input"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Tooltip from "../components/tooltip";
import {usePatientOrder} from '../data/patientOrder'


export default function AddressCombobox({handleAddressSelect, label, isLoading, value, error}) {
	const [addresses, setAddresses] = useState([]);
	const [filteredAddresses, setFilteredAddresses] = useState([])
	const [addressQuery, setAddressQuery] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);

	const params = new URLSearchParams(window.location.search);
	const token = params.get('token');

	const { patientOrder } = usePatientOrder(token);

	const [pafMethod, setPafMethod] = useState('NzPaf'); // Default to 'NzPaf'

	useEffect(() => {
		if (patientOrder) {
			const companyCode = patientOrder?.practitioner?.company_code;
			setPafMethod(companyCode === 'ihal' ? 'AuPaf' : 'NzPaf');
		}
	}, [patientOrder]);

	const debouncedFetchAddresses = useCallback(
		throttle(async (query, method) => {
			if (!query) {
				setAddresses([]);
				setIsProcessing(false);
				return;
			}
			setIsProcessing(true);
			const data = await fetchAddressesFromKleber(query, method);
			if (data?.DtResponse?.Result && data.DtResponse.Result.length > 0) {
				setAddresses(data.DtResponse.Result);
				setIsProcessing(false);
			} else {
				setAddresses([]);
			}
		}, 300),
		[]
	);

	useEffect(() => {
		setAddressQuery(value)
	}, [value])

	useEffect(() => {
	  debouncedFetchAddresses(addressQuery, pafMethod);

	  return () => {
	    debouncedFetchAddresses.cancel();
	  };
	}, [addressQuery, debouncedFetchAddresses, pafMethod]);

	async function fetchAddressesFromKleber(inputValue, method){
	  const apiEndpoint = 'https://integria.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest';
		const queryParams = new URLSearchParams({
	    Method: `DataTools.Capture.Address.Predictive.${method}.SearchAddress`,
	    AddressLine: inputValue,
	    RequestKey: process.env.KLEBER_KEY,
	    SearchOption: 'A002',
	    OutputFormat: 'json',
	    ResultLimit: 100
	  }); 

	  try {
	    const response = await fetch(`${apiEndpoint}?${queryParams}`);
	    const data = await response.json();
	    return data
	  } catch (error) {
	    console.error('Error fetching data: ', error);
	    return [];
	  }
	}

	useEffect(() => {
	  if (!addresses) {
	  	setFilteredAddresses([])
	  	return
	  } else {
		  setFilteredAddresses(addresses.filter((address) =>
		    address.AddressLine
		      .toLowerCase()
		      .replace(/\s+/g, '')
		      .includes(addressQuery.toLowerCase().replace(/\s+/g, ''))
		  ))
	  }
	}, [addresses, addressQuery])

	const handleOnChange = address => {
		// setAddressQuery('')
		handleAddressSelect(address.RecordId)
	}

	if (isLoading) return <SkeletonInput label={`${label} Address`} />

  return (
    <Combobox as="div" value={value} onChange={handleOnChange}>
			<div className="tw-flex tw-items-center tw-gap-2">
				<InputLabel error={error} htmlFor={`${label.toLowerCase()}-address`}>
					{label} Address
				</InputLabel>
				<Tooltip text="Start typing and select an address from the dropdown." />
			</div>
      <div className="tw-relative tw-mt-1">
				<div className="tw-relative tw-rounded-md tw-border-gray-300 focus:tw-ring-0 focus:tw-border-blue-400">
					<div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
						<MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
					</div>
					<Combobox.Input
						id={`${label.toLowerCase()}-address`}
						value={addressQuery}
						placeholder="Start typing your address"
						className={`address-input tw-pl-10 tw-shadow-sm tw-block tw-w-full tw-rounded-md tw-border-gray-3000 focus:tw-ring-0 focus:tw-border-blue-400 sm:tw-text-sm disabled:tw-cursor-not-allowed tw-placeholder-gray-400 ${error ? 'tw-ring-1 tw-ring-red-400' : '' }`}
						onChange={event => setAddressQuery(event.target.value)}
					/>
				</div>
				{(isProcessing || filteredAddresses.length !== 0) && 
	        <Combobox.Options className="address-options tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm">
						{isProcessing ? (
							<div className="tw-flex tw-items-center tw-justify-center tw-py-2">
								<svg className="tw-animate-spin tw-h-5 tw-w-5 tw-text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
								</svg>
							</div>
						) : (
							filteredAddresses.map((address) => (
								<Combobox.Option
									key={address.RecordId}
									value={address}
									className='address-option tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9 tw-text-gray-900'
								>
									<span className='tw-block tw-truncate'>
										{address.AddressLine}, {pafMethod === 'AuPaf' ? address.Locality : address.Suburb}, {address.TownCityMailtown} {pafMethod === 'AuPaf' ? address.State : ''} {address.Postcode}
									</span>
								</Combobox.Option>
							))
						)}
	        </Combobox.Options>
        }
      </div>
    </Combobox>
  )
}