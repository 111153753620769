import React, { useState, useEffect } from "react";
import AddressCombobox from "./AddressCombobox";
import { InputField } from "../components/input";
import { usePatientOrder } from '../data/patientOrder';
import { gtmTriggerEvent } from "../data/gtm";

export default function AddressFields({ label, setAddress, address, errors, setErrors }) {
  const prefix = label ? `${label.toLowerCase()}` : '';
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const { patientOrder, isLoading } = usePatientOrder(token);

  const [pafMethod, setPafMethod] = useState({ method: 'NzPaf', suburbField: 'Suburb', cityField: 'TownCityMailtown' }); // Default to 'NzPaf'

  useEffect(() => {
    if (patientOrder) {
      const companyCode = patientOrder?.practitioner?.company_code;
      setPafMethod(companyCode === 'ihal' 
        ? { method: 'AuPaf', suburbField: 'Locality', cityField: 'State' } 
        : { method: 'NzPaf', suburbField: 'Suburb', cityField: 'TownCityMailtown' });
    }
  }, [patientOrder]);

  const gtmTriggerAddShippingInfo = () => {
    const items = patientOrder.line_items.map((lineItem) => ({
      item_name: lineItem.name,
      price: lineItem.patient_price,
      quantity: lineItem.ordering_quantity,
    }));

    gtmTriggerEvent('add_shipping_info', {
      currency: 'NZD',
      value: parseFloat(patientOrder?.shipping.rate),
      items: items,
    });
  };

  async function fetchAddress(recordId) {
    gtmTriggerAddShippingInfo();
    const apiEndpoint =
      'https://integria.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest';
    const queryParams = new URLSearchParams({
      Method: `DataTools.Capture.Address.Predictive.${pafMethod.method}.RetrieveAddress`,
      RecordId: recordId,
      RequestKey: process.env.KLEBER_KEY,
      OutputFormat: 'json',
    });

    try {
      const response = await fetch(`${apiEndpoint}?${queryParams}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      return [];
    }
  }

  const handleAddressSelect = (recordId) => {
    fetchAddress(recordId).then((data) => {
      if (data && data.DtResponse && data.DtResponse.Result && data.DtResponse.Result[0]) {
        const address = data.DtResponse.Result[0];
        setAddress({
          address: address.AddressLine,
          suburb: address[pafMethod.suburbField],
          city: address[pafMethod.cityField],
          postalCode: address.Postcode,
        });
      }
    });
  };

  useEffect(() => {
    if (patientOrder?.patient) {
      setAddress({
        address:
          address.address || patientOrder.patient[`${prefix}_address`] || '',
        suburb:
          address.suburb || patientOrder.patient[`${prefix}_suburb`] || '',
        city:
          address.city || patientOrder.patient[`${prefix}_city`] || '',
        postalCode:
          address.postalCode ||
          patientOrder.patient[`${prefix}_postcode`] ||
          '',
      });
    }
  }, [patientOrder, prefix, setAddress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevAddress => ({
      ...prevAddress,
      [name]: value
    }));

    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: false
      }));
    }
  };

  return (
    <>
      <div className="tw-col-span-full">
        {prefix === 'shipping' ? (
          <AddressCombobox
            isLoading={isLoading}
            error={errors.address}
            label={label}
            handleAddressSelect={handleAddressSelect}
            value={address.address}
          />
        ) : (
          <InputField
            label={`${label} Address`}
            type="text"
            value={address.address || ''}
            id={`${prefix}-address`}
            name="address"
            onChange={handleInputChange}
            autoComplete="address-line1"
            isLoading={isLoading}
            error={errors.address}
          />
        )}
      </div>

      <div className="tw-col-span-full sm:tw-col-span-8">
        <InputField
          label="Address Line 2"
          type="text"
          value={address.suburb || ''}
          id={`${prefix}-suburb`}
          name="suburb"
          onChange={handleInputChange}
          autoComplete="address-level1"
          isLoading={isLoading}
          error={errors.suburb}
        />
      </div>

      <div className="tw-col-span-full sm:tw-col-span-4">
        <InputField
          label={pafMethod.method === 'AuPaf' ? "State" : "City"}
          type="text"
          id={`${prefix}-city`}
          name="city"
          onChange={handleInputChange}
          value={address.city || ''}
          autoComplete="address-level2"
          isLoading={isLoading}
          error={errors.city}
        />
      </div>

      <div className="tw-col-span-full sm:tw-col-span-8">
        <InputField
          label="Business Name"
          type="text"
          value={address.business || ''}
          id={`${prefix}-business`}
          name="business"
          onChange={handleInputChange}
          isLoading={isLoading}
          error={errors.business}
        />
      </div>

      <div className="tw-col-span-full sm:tw-col-span-4">
        <InputField
          label="Postal Code"
          type="text"
          onChange={handleInputChange}
          id={`${prefix}-postal-code`}
          name="postalCode"
          value={address.postalCode || ''}
          autoComplete="postal-code"
          isLoading={isLoading}
          error={errors.postalCode}
        />
      </div>
    </>
  );
}