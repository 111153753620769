import React from "react"
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

export const InputField = ({ id, type, label, name, autoComplete, disabled, value, onChange, error, isLoading=false }) => {
 if (isLoading) return <SkeletonInput label={label} />

  return (
    <div className="tw-col-span-full">
      <InputLabel htmlFor={id} error={error} className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
        {label}
      </InputLabel>
      <div className="tw-mt-1">
        <input
          key={id}
          type={type}
          id={id}
          placeholder={label}
          name={name}
          onChange={onChange}
          autoComplete={autoComplete}
          disabled={disabled}
          value={value}
          className={`tw-shadow-sm tw-block tw-w-full tw-rounded-md tw-border-gray-300 focus:tw-ring-0 focus:tw-border-blue-400 sm:tw-text-sm disabled:tw-cursor-not-allowed tw-placeholder-gray-400 ${error ? 'tw-ring-1 tw-ring-red-400' : '' }`}
        />
      </div>
    </div>
  );
};

export const SkeletonInput = ({label}) => {
  return (
    <div className="tw-col-span-full">
      <div className="tw-flex">
        <span className="tw-animate-pulse tw-text-sm tw-font-medium tw-bg-gray-200 tw-text-gray-200">{label}</span>
      </div>
      <div className="tw-mt-2 tw-animate-pulse">
        <div className="tw-block tw-w-full tw-rounded-md tw-bg-gray-200 tw-px-3 tw-py-2 sm:tw-text-sm tw-border-gray-200 tw-border">&nbsp;</div>
      </div>
    </div>
  )
}

export const InputLabel = ({htmlFor, children, error}) => {
  return (
    <label htmlFor={htmlFor} className="tw-flex tw-text-sm tw-font-medium tw-text-gray-700">
      {children}{error ? <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-ml-2 tw-text-red-500"/> : ''}
    </label>
  )
}