import useSWR from 'swr';
import axios from 'axios';

const fetcher = ([url, auth_header]) => axios.get(url, {headers: auth_header}).then(res => res.data)

export default function usePatientList(page, search, auth_header) {
  const { data, error } = useSWR([`/api/practitioner/patients?page=${page}&search=${search}`, auth_header], fetcher);
  return {
    patients: data ? data.patients : [],
    total_count: data ? data.total_count : null,
    isLoading: !error && !data,
    isError: error
  };
}
