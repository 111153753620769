import React, { useState } from 'react';
import useOrderHistory from '../data/orderHistory';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import AuthService from '../services/authentication'
import { useHistory } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import LoadingComponent from '../helpers/LoadingComponent';
import PrescriptionTable from './PrescriptionTable'; 
import CreateOrderForm from "../sales_orders/CreateOrderForm"
import Modal from 'react-bootstrap/Modal';


function Prescriptions() {
  const [page, setPage] = useState(0);
  const [key, setKey] = useState('all');
  const [search, setSearch] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  let auth = AuthService.useAuth()
  const { orders, total_count, isLoading, isError } = useOrderHistory(page, key, submittedSearch, auth.authHeader());
  const history = useHistory()
  const totalPages = Math.ceil(total_count / 20);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  }

  const handleSearch = () => {
    setSubmittedSearch(search);
  }

  return (
    <Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateOrderForm  />
        </Modal.Body>
      </Modal>
      <div className="d-flex w-50 mb-3">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={handleSearchChange}
          className="form-control me-2"
          onKeyPress={event => event.key === 'Enter' && handleSearch()}
        />
        <Button variant="secondary" onClick={handleSearch}>Search</Button>
      </div>
      <div className="d-flex justify-content-between">
        <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1">Prescriptions</h2>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <span className="me-2">Create new prescription</span>
        </Button>
      </div>
      <Nav className="internal-nav" activeKey={key} onSelect={k => {setPage(0); setKey(k);}}>
        <Nav.Item>
          <Nav.Link className='text-black' eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className='text-black' eventKey="completed">Completed</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className='text-black' eventKey="partial">Partial</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className='text-black' eventKey="unordered">Unordered</Nav.Link>
        </Nav.Item> 
        <Nav.Item>
          <Nav.Link className='text-black' eventKey="unsent">Unsent</Nav.Link>
        </Nav.Item>
      </Nav>

      {isLoading ? (
        <LoadingComponent />
      ) : isError ? (
        <div>Error: {isError.message}</div>
      ) : (
        <>
          <PrescriptionTable orders={orders} history={history} />
          <Pagination className="w-100 d-flex justify-content-between">
            <p className="ms-2 mb-0">{`${total_count} results`}</p>
            <div className="d-flex">
              <Pagination.Item onClick={() => setPage(old => Math.max(old - 1, 0))} disabled={page === 0} >Prev</Pagination.Item>
              <Pagination.Item onClick={() => setPage(old => old + 1)}  disabled={page === totalPages - 1} >Next</Pagination.Item>
            </div>
          </Pagination>
        </>
      )}

    </Container>
  );
}

export default Prescriptions;
