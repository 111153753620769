import MixtureImage from 'images/mixture_image.png'

export const parseMixtures = mixtures => {
	let parsed_items = []
	mixtures.map(mixture => {
		let mixtureItem = {id: mixture.id, name: mixture.name ? mixture.name : 'Custom Formulation', quantity: mixture.quantity, mixture: true, max_weight: mixture.max_weight, sales_order_items: parseSalesOrderItems(mixture.sales_order_items), image_url: MixtureImage, note: mixture.note} 
		parsed_items.push(mixtureItem)
	})
	return parsed_items
}

export const parseSalesOrderItems = sales_order_items => {
	let parsed_items = []
	sales_order_items.map(sales_order_item => {
	  let item = {...sales_order_item.product, note: sales_order_item.note, quantity: sales_order_item.quantity, rrp: sales_order_item.product.rrp,  price: sales_order_item.practitioner_price, product: sales_order_item.product, markup: sales_order_item.markup, use_rrp: sales_order_item.use_rrp}
	  parsed_items.push(item)
	})
	return parsed_items
}

export const parseLineItems = order => {
	const line_items = parseSalesOrderItems(order.sales_order_items).concat(parseMixtures(order.mixtures))
  return line_items
}

export const sortHerbsAlphabetically = lineItems => { 
  lineItems.sort((a, b) => {
    const lowerA = a.name.toLowerCase()
    const lowerB = b.name.toLowerCase()

    if (lowerA < lowerB) {
      return -1
    }
    if (lowerB < lowerA) {
      return 1
    }
    return 0
  })
  return lineItems
}

export const calcMixItemPrice = item => {
  let price
  if(item.use_rrp) {
    price = item.rrp
  } else {
    price = item.practitioner_price
    price += item.markup ? item.price * (parseInt(item.markup) / 100) : 0
    price += (price * 0.15) // GST
  }
  price = (!isNaN(item.quantity) ? item.quantity : 0) * price
  return price
}

export const calcMixTotal = (line_items, quantity=1, medicine_cup=false) => {
  let total = 9.77
  line_items.map(item => {
    let price = calcMixItemPrice(item)
    total += price
  })
  total = total * quantity
  return total
}








