import axios from "axios"
import useSWR from 'swr'

const key = `/api/practitioner/favourites`

const fetcher = ([url, auth_header]) => axios.get(url, {headers: auth_header}).then(res => res.data)

function addFavourite({product_id, auth_header}) {
  return axios.post(`/api/practitioner/favourite`, {product_id: product_id}, {headers: auth_header})
}

function deleteFavourite({product_id, auth_header}) {
  return axios.delete(`/api/practitioner/favourite`, {data: {product_id: product_id}, headers: auth_header})
}

function checkIsFavourite(product_id, favourites) {
  return product_id && favourites && favourites.find(favourite => favourite.id == product_id)
}

function useFavourites(auth_header) {
  const {data, error, isValidating, mutate} = useSWR([key, auth_header], fetcher)

  return {
    favouriteProducts: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate
  }
}

export {
  useFavourites,
  addFavourite,
  deleteFavourite,
  checkIsFavourite
}
