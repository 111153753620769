import React from "react"
import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Notification({cookieName, title, content}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem(cookieName) === 'true';
    if (!hasSeenNotification) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem(cookieName, 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  function TextWithLineBreaks({ text }) {
    return (
      <>
        {text.split('\n').map((line, index, array) =>
          index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tw-pointer-events-none tw-fixed tw-inset-0 tw-flex tw-items-end tw-px-4 tw-py-6 sm:tw-items-start sm:tw-p-6 tw-z-10" >
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
          <Transition
            show={isVisible}
            as={Fragment}
            enter="tw-transform ease-out tw-duration-300 tw-transition"
            enterFrom="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
            enterTo="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div className="tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
              <div className="tw-p-4">
                <div className="tw-flex tw-items-start">
                  <div className="tw-flex-shrink-0">
                    <SparklesIcon className="tw-h-6 tw-w-6 tw-text-green-400" aria-hidden="true" />
                  </div>
                  <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                    <p className="tw-text-sm tw-font-medium tw-text-gray-900">{title}</p>
                    <p className="tw-mt-1 tw-text-sm tw-text-gray-500"><TextWithLineBreaks text={content} /></p>
                  </div>
                  <div className="tw-ml-4 tw-flex tw-flex-shrink-0">
                    <button
                      type="button"
                      className="tw-inline-flex tw-rounded-md tw-bg-white tw-text-gray-400 tw-hover:text-gray-500 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-indigo-500 tw-focus:ring-offset-2"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      <XMarkIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
