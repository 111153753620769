import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.GTM_CONTAINER_ID
}

function gtmInit() {
  TagManager.initialize(tagManagerArgs)
}

const gtmTriggerEvent = (eventName, ecommerceData = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ecommerce: {
        ...ecommerceData
      }
    }
  });
};

export {
  gtmInit,
  gtmTriggerEvent
}