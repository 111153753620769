import React from "react"


const PaymentSuccess = props => {

	return (
		<>
		  <main className="tw-relative lg:tw-min-h-full">
		    <div>
		      <div className="tw-mx-auto tw-max-w-2xl lg:tw-max-w-7xl">
	          <h1 className="tw-text-sm tw-font-medium tw-text-integria-green">Payment successful</h1>
	          <p className="tw-mt-2 tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Thank you for your order</p>
	          <p className="tw-mt-2 tw-text-base tw-text-gray-500">
	          	For any queries regarding your prescription, please contact your practitioner directly.
	          </p>
          </div>
		    </div>
		  </main>
		</>
	)
}

export default PaymentSuccess