import React, {useEffect, useState} from "react"
import axios from "axios"
import AuthService from '../services/authentication'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'


function SetRrpForm(props) {

	const [defaultRrp, setDefaultRrp] = useState(false)
	let auth = AuthService.useAuth()

	const handleInputChange = () => {
		const value = !defaultRrp
		setDefaultRrp(value)
	}

	useEffect(() => {
		updateServer()
	}, [defaultRrp])

	useEffect(() => {
	  axios.get('/api/practitioner/default-rrp', {headers: auth.authHeader()})
	  .then(response => {
	    setDefaultRrp(response.data)
	  })
	  .catch(error => {
	    console.log(error)
	  })
	}, [])

	const updateServer = () => {
		axios.post(`/api/practitioner/default-rrp`, 
		  {
		    rrp: defaultRrp
		  },
		  {
		    headers: auth.authHeader(),
		  }
		)
		.then(response => {
		  // console.log(response)
		})
		.catch(error => {
		  console.log(error)
		})
	}

	return (
		<Form >
	  	<Form.Label>Set your default pricing option</Form.Label>
			<Form.Label > </Form.Label>
	  	<Form.Check 
		  	onChange={handleInputChange}
        type='radio'
        checked={defaultRrp}
        label='RRP'
      />
	  	<Form.Check 
		  	onChange={handleInputChange}
        type='radio'
        checked={!defaultRrp}
        label='Markup'
      />
	    <Form.Text id="passwordHelpBlock" muted>
	    	This can be overidden on a per order basis.
	    </Form.Text>
		</Form>
	)
}

export default SetRrpForm