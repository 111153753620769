import React, { useState } from "react";
import { useMixture } from '../data/mixture';
import { sortHerbsAlphabetically } from '../helpers/Helpers';
import { Row, Col, Button, Form, InputGroup, FormControl, Image, Alert } from 'react-bootstrap';
import AuthService from '../services/authentication';
import { useHistory } from "react-router-dom";
import MixtureImage from 'images/mixture_image.png';

function MixtureLineItem({ mixture, noteDrafts, setNoteDrafts, markupDrafts, setMarkupDrafts, handleDraftNoteChange, handleDraftMarkupChange, deleteMixture, saveOrder }) {
  let auth = AuthService.useAuth();
  let history = useHistory();
  const { mixture: mixtureData, updateProperty } = useMixture(mixture.id, auth.authHeader());

  if (!mixtureData) return null;

  const mixtureQuantityForm = () => (
    <Form className="d-inline-flex align-items-center">
      <InputGroup>
        <Button
          disabled={mixtureData.quantity === 0}
          variant="outline-secondary"
          onClick={() => {
            const newQuantity = mixtureData.quantity - 1;
            if (newQuantity === 0) {
              deleteMixture(mixtureData);
            } else {
              updateProperty('quantity', newQuantity);
            }
          }}
        >
          -
        </Button>
        <FormControl style={{ maxWidth: '3rem', textAlign: 'center' }} value={mixtureData.quantity || 0} readOnly />
        <Button
          variant="outline-secondary"
          onClick={() => updateProperty('quantity', mixtureData.quantity + 1)}
        >
          +
        </Button>
      </InputGroup>
    </Form>
  );

  return (
    <Row className="mt-4">
      <Col xs={2} className="d-flex justify-content-center">
        <Image
          thumbnail
          src={MixtureImage}
          style={{ aspectRatio: '1/1', width: '100%', objectFit: 'contain', objectPosition: 'center' }}
        />
      </Col>
      <Col xs={7}>
        <Row className="d-flex align-content-between h-100">
          <Col xs={12}>
            <h4 className="tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-2">
              {mixtureData.name ? mixtureData.name : 'New Mixture'}
            </h4>
          </Col>
          <Col xs={12}>
            {mixtureQuantityForm()}
            {mixtureData.herbs && sortHerbsAlphabetically(mixtureData.herbs).map(item => (
              <p className="my-2" key={item.id}>
                <span className="fw-bold">{item.name}</span> {item.quantity}ml
              </p>
            ))}
            <Form.Control
              name="notes"
              as="textarea"
              placeholder="Notes.."
              value={noteDrafts[mixtureData.id] !== undefined ? noteDrafts[mixtureData.id] : mixtureData.note}
              onChange={(e) => handleDraftNoteChange(mixtureData.id, e.target.value)}
              className="my-2"
            />
            <div className="my-2">
              <Button
                onClick={async () => {
                  await saveOrder();
                  history.push(`/order/mixtures/${mixtureData.id}/edit`);
                }}
                variant="secondary"
                className="me-2"
              >
                Blend Herbs
              </Button>
              <Button onClick={() => deleteMixture(mixtureData)} variant="danger">Remove</Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="d-flex align-content-end justify-content-end flex-wrap">
        <h4 className="tw-text-2xl">${(mixtureData.patient_price * mixtureData.quantity).toFixed(2)}</h4>
      </Col>
    </Row>
  );
}

export default MixtureLineItem;