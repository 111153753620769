import React, { useState, useEffect } from "react"
import { usePlacesWidget } from "react-google-autocomplete"
var _ = require('lodash')

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'


const ShippingForm = props => {

	const shippingOptions = [
		{name: 'Local (Canterbury)', price: 7.48},
		{name: 'South Island', price: 10.93},
		{name: 'North Island', price: 10.93},
		{name: 'Rural', price: 17.25},
	]

	const { ref, autocompleteRef } = usePlacesWidget({
	  apiKey: process.env.GOOGLE_API_KEY,
	  options: {
	    types: ['address'],
	    componentRestrictions: { country: "nz" },
	  },
	  onPlaceSelected: place => {
	  	const postcode = _.find(place.address_components, o => o.types[0] == 'postal_code') || {}
	  	const city = _.find(place.address_components, o => o.types[0] == 'locality') || {}
	    props.setAddressFormValues({
	      ...props.addressFormValues,
	      address: place.formatted_address,
	      postcode: postcode.long_name,
	      city: city.long_name
	    })
	    props.setHasSelectedAddress(true)
	  }
	})

	const handleInputChange = event => {
		event.name == 'address' && props.setHasSelectedAddress(false)
	  const target = event.target
	  const value = target.value
	  const name = target.name
	  props.setAddressFormValues({
	    ...props.addressFormValues,
	    [name]: value
	  })
	}

	const handleRadioChange = event => {
	  const target = event.currentTarget
	  const value = target.value
	  props.setShippingPrice(parseFloat(value))
	}

	const proceedToNextPage = e => {
		e.preventDefault()
		if(props.hasSelectedAddress) {
			props.onCheckoutStepChange(3)
		} else {
			const addressInput = _.find(e.target, {name: 'address'})
			addressInput.setCustomValidity('Please select an address from the dropdown.')
			addressInput.reportValidity()
			setTimeout(() => {
				addressInput.setCustomValidity("")
			}, 1000)
		}
	}

	return (
		<>
		<Form onSubmit={proceedToNextPage}>
			<Form.Group>
				<Form.Label className="w-100">Address</Form.Label>
			  <Form.Control name="address" type="text" placeholder="Please enter your address..." ref={ref} onChange={handleInputChange} value={props.addressFormValues.address} />
			</Form.Group>
			<Form.Group className="mt-3">
				<Form.Label className="w-100">Region</Form.Label>
				<ButtonGroup>
		    	{shippingOptions.map((option, i) => (
					<ToggleButton
						key={i}
						type="radio"
						id={`radio-${i}`}
						variant="outline-secondary"
						name="radio"
						value={parseFloat(option.price)}
						checked={props.shippingPrice == option.price}
						onChange={e => handleRadioChange(e)} >
						{option.name}
					</ToggleButton>
		    	))}
	    	</ButtonGroup>
			</Form.Group>
			<Form.Group className="mt-3">
				<Form.Label className="w-100">Delivery details (optional)</Form.Label>
		    <Form.Control style={{maxWidth: '30em'}} name="delivery_details" as="textarea" type="text" placeholder="e.g. permission to leave parcel at door." onChange={handleInputChange} value={props.addressFormValues.delivery_details} />
			</Form.Group>
	    {/*<p className="text-muted mt-4">Ensure the correct freight zone is selected, or additional charges will apply. To confirm your freight zone, please use <a href="https://www.posthaste.co.nz/address_checker.html" style={{color: 'inherit'}} target="_blank">this tool</a>.</p>*/}
	    <p className="text-muted mt-4" style={{fontSize: '0.9em'}}>Patient Orders placed via our app, will be dispatched on our express courier service (NZ Post), unless the order contains liquids, in which case due to CAA regulations the order will travel on our standard courier service. This also applies where a delivery address is rural. PO Boxes are supported via the express delivery service, however, liquids cannot travel via the express service, so in instances where an order contains a liquid, PO box addresses cannot be used. Please select another address for delivery in these instances.</p>
	    <h4 className="fw-bold mt-1">Shipping: ${props.shippingPrice.toFixed(2)}</h4>
	    <h4 className="fw-bold mt-1">Sub Total: ${props.orderTotal.toFixed(2)}</h4>
			<Button variant="secondary" className="mt-4 me-2" onClick={() => props.onCheckoutStepChange(1)}>Back</Button>
			<Button type="submit" className="mt-4">Continue To Payment</Button>
		</Form>
		</>
	)
}

export default ShippingForm