import React, {useContext, createContext, useState, useEffect} from "react"
import axios from "axios"
import Cookies from 'js-cookie'

const api_url = "/api/login/"

const authContext = createContext()

function useAuth() {
  return useContext(authContext)
}

function ProvideAuth({children}) {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

function useProvideAuth() {

  const COOKIE_NAME = 'integria_pop_user_1'

  const [user, setUser] = useState(() => {
    const userFromCookie = Cookies.get(COOKIE_NAME)
    return userFromCookie ? JSON.parse(userFromCookie) : null
  })

  const [authStatus, setAuthStatus] = useState('pending') 

  useEffect(() => {
    checkAuthStatus()
  }, [user])

  async function checkAuthStatus() {
    if (!user || !user.token) {
      setAuthStatus('unauthenticated');
      return;
    }
    try {
      const response = await fetch(api_url + 'validate-token', {
        method: 'GET',
        headers: authHeader()
      });
  
      if (!response.ok) {
        throw new Error('Error validating token: ' + response.status);
      }
  
      setAuthStatus('authenticated');
    } catch (error) {
      console.error('Error validating token:', error);
      setAuthStatus('unauthenticated');
      Cookies.remove(COOKIE_NAME);
    }
  }

  const authHeader = () => {
    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token }
    } else {
      return {}
    }
  }

  const createSignInLink = email => {
    return axios
      .post(api_url + "practitioner-token", {
        email: email
      })
      .then(response => {
        return response.data
      })
  }  

  const initVerifySms = token => {
    return axios
      .post(api_url + "sms-init", {
        token: token
      })
      .then(response => {
        return response
      })
  }  

  const verifySms = (token, code) => {
    return axios
      .post(api_url + "sms-verify", {
        token: token, 
        code: code
      })
      .then(response => {
        setUser(response.data)
        Cookies.set(COOKIE_NAME, JSON.stringify(response.data), { expires: 7 }) 
        return response
      })
  }  

  const signIn = token => {
    return axios.get('/api/login/practitioner',
        {
          params: {
            token: token,
          }
      })
      .then(response => {
        setUser(response.data)
        Cookies.set(COOKIE_NAME, JSON.stringify(response.data), { expires: 7 }) 
      })
      .catch(error => {
        console.log(error)
      })
  } 

  const signOut = () => {
    return (
      axios.post('/api/logout', {}, {headers: authHeader()})
      .then(() => {
        Cookies.remove('popUser') 
        setUser(null)
      })
    )
  }

  return {
    user,
    signIn,
    signOut,
    createSignInLink,
    initVerifySms,
    verifySms,
    authHeader,
    authStatus,
    checkAuthStatus
  }
}

export default {
  useAuth,
  ProvideAuth,
  useProvideAuth
}
