import React, {useEffect, useState} from "react"
import axios from "axios"
import AuthService from '../services/authentication'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'


function SetMarkupForm(props) {

	const [markup, setMarkup] = useState(0)
	let auth = AuthService.useAuth()

	const handleInputChange = event => {
		const value = event.target.value
		setMarkup(value)
	}

	useEffect(() => {
	  axios.get('/api/practitioner/markup', {headers: auth.authHeader()})
	  .then(response => {
	    setMarkup(response.data)
	  })
	  .catch(error => {
	    console.log(error)
	  })
	}, [])

	const handleSubmit = event => {
		event.preventDefault()
		axios.post(`/api/practitioner/markup`, 
		  {
		    markup: markup
		  },
		  {
		    headers: auth.authHeader(),
		  }
		)
		.then(response => {
		  console.log(response)
		})
		.catch(error => {
		  console.log(error)
		})
	}

	return (
		<Form onSubmit={handleSubmit}>
		  	<Form.Label >Set your default markup rate</Form.Label>
		  	<InputGroup>
		  	    <Form.Control name="markup" type="number" placeholder="0" onChange={e => e.target.value >= 0 && e.target.value <= 300 && handleInputChange(e)} value={markup} min={0} step={0.01} />
		  	    <InputGroup.Text>%</InputGroup.Text>
		  	    <Button variant="secondary" type="submit">Save</Button>
		  	</InputGroup>
		    <Form.Text id="passwordHelpBlock" muted>
		    	This can be overidden on a per order basis.
		    </Form.Text>
		</Form>
	)
}

export default SetMarkupForm