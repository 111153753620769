import React from "react"

import { useHistory } from "react-router-dom";
import useSWR from 'swr';
import Button from 'react-bootstrap/Button';
import AuthService from '../services/authentication'
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { useOrder, updateLineItem, sendOrder } from '../data/order';

function OrderSummary() {
  let history = useHistory();
  const auth = AuthService.useAuth();
  const { order, mutate, isLoading, isError } = useOrder(auth.authHeader());

  const hasItems = () => {
    return [...order.product_line_items, ...order.mixture_line_items].length !== 0
  }

  if(isLoading) return <div>Loading...</div>;
  if(isError) return <div>Error occurred!</div>;

  const renderLineItems = () => {
    return [...order.product_line_items, ...order.mixture_line_items].map((lineItem, i) => (
      <ListGroup.Item key={lineItem.id} className="mt-3">
        <div>
          <p className="w-100">{lineItem.mixture ? 'Custom herbal formulation' : lineItem.name}</p>
        </div>
      </ListGroup.Item>
    ));
  }

  return (
    <Card>
      <Card.Header>Prescribed Products List</Card.Header>
      <ListGroup variant="flush">
        {hasItems() ? renderLineItems() : <span className="text-muted py-2 ms-3">No products</span>}
      </ListGroup>
      <Card.Body>
        <Button variant="primary" onClick={() => history.push('/order')} disabled={!hasItems()}>Add Details</Button>
      </Card.Body>
    </Card>
  )
}

export default OrderSummary;
