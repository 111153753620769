import React from "react"
import MixtureImage from 'images/mixture_image.png'
import {usePatientOrder, updateLineItemQuantity, optimisticLineItemUpdate} from '../data/patientOrder'
import { CheckIcon, ClockIcon, ClipboardDocumentCheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Tooltip from "../components/tooltip";

export default function OrderSummary(props) {

  const {patientOrder, isLoading, isError, mutate} = usePatientOrder(props.token)
  
  const calcPrice = lineItem => `$${(lineItem.patient_price * lineItem.ordering_quantity).toFixed(2)}`

  const renderLineItem = (lineItem) => {
    return (
      <li key={lineItem.id} className="product-item tw-flex tw-px-4 tw-py-6 sm:tw-px-6">
        <div className="tw-flex-shrink-0 tw-max-h-20">
          <img src={lineItem.mixture ? MixtureImage : lineItem.image_url}  className="tw-w-10 tw-max-h-full tw-rounded-md tw-object-contain" />
        </div>

        <div className="tw-ml-6 tw-flex tw-flex-1 tw-flex-col">
          <div className="tw-flex">
            <div className="tw-min-w-0 tw-flex-1">
              <div className="tw-flex tw-flex-1 tw-items-end tw-justify-between tw-pt-2">
                <h4 className="tw-text-sm tw-font-medium tw-text-gray-700">
                    {lineItem.name}
                </h4>
                <p className="tw-mt-1 tw-text-sm tw-font-medium tw-text-gray-900 tw-ml-4">${lineItem.patient_price.toFixed(2)}</p>
              </div>
              <div>
                {lineItem.mixture && lineItem.herbs && <p className="tw-mt-1 tw-text-sm tw-text-gray-700">{lineItem.herbs}</p>}
                {lineItem.note && <p className="tw-mt-1 tw-text-sm tw-text-gray-700">{lineItem.note}</p>}
              </div>
            </div>
          </div>

          <div className="tw-flex tw-flex-1 tw-items-end tw-justify-between tw-pt-2">
            {itemStatus(lineItem)}
            {quantityForm(lineItem)}
          </div>
        </div>
      </li>
    )
  }

  const itemStatus = lineItem => {
    let statusDetails;

    if (lineItem.fully_ordered) {
      statusDetails = {
        icon: <ClipboardDocumentCheckIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-300" />,
        text: 'Fully Ordered'
      };
    } else if (!lineItem.active) {
      statusDetails = {
        icon: <ExclamationTriangleIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-300" />,
        text: 'Product Discontinued'
      };
    } else if (lineItem.max_q == 0) {
      statusDetails = {
        icon: <ClockIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-300" />,
        text: 'Out of stock'
      };
    } else {
      statusDetails = {
        icon: <CheckIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-green-500" />,
        text: 'In stock'
      };
    }

    return (
      <p className="tw-mt-4 tw-flex tw-space-x-2 tw-text-sm tw-text-gray-500">
        {statusDetails.icon}
        <span>{statusDetails.text}</span>
      </p>
    );
  }

  const handleQuanityChange = (line_item_id, quantity, is_mixture) => {
    mutate(updateLineItemQuantity({token: props.token, line_item_id: line_item_id, quantity: quantity, is_mixture: is_mixture}), {
      optimisticData: patientOrder => optimisticLineItemUpdate(patientOrder, line_item_id, 'ordering_quantity', quantity),
      populateCache: true,
    })
  }

  const quantityForm = lineItem => {

    const options = Array.from({ length: lineItem.max_q + 1 }, (_, i) => (
      <option key={i} value={i} >
        {i}
      </option>
    ));

    return (
      <div className={`tw-ml-4 tw-mt-4 tw-flex tw-items-start tw-gap-2`}>
        <Tooltip text="Select the quantity to purchase now, up to the prescribed maximum. Remaining quantity can be ordered later." />
        <select
          id="quantity"
          name="quantity"
          disabled={lineItem.max_q == 0}
          onChange={e => handleQuanityChange(lineItem.id, parseInt(e.target.value), lineItem.mixture)}
          className="tw-rounded-md tw-border tw-border-gray-300 tw-text-left tw-text-base tw-font-medium tw-text-gray-700 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 sm:tw-text-sm disabled:tw-bg-gray-100 disabled:tw-opacity-60"
          defaultValue={lineItem.ordering_quantity}
        >
          {options}
        </select>
      </div>
    )
  }

  const renderLineItems = () => {
    return patientOrder.line_items && patientOrder.line_items.map((lineItem, i) => {
      return renderLineItem(lineItem)
    })
  }

  const Skeleton = () => {
    return (
        <div className="tw-animate-pulse">
          <div className="tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-shadow-sm">
            <ul role="list" className="tw-divide-y tw-divide-gray-200">
              <li className="tw-flex tw-px-4 tw-py-6 sm:tw-px-6">
                <div className="tw-flex-shrink-0 tw-h-20 tw-bg-gray-200 tw-w-10" ></div>
                <div className="tw-ml-6">
                  <div className="tw-flex tw-flex-col tw-items-start">
                    <h4 className="tw-text-sm tw-font-medium tw-text-gray-200 tw-bg-gray-200">
                      Lorem ipsum
                    </h4>
                    <p className="tw-mt-1 tw-text-sm tw-text-gray-200 tw-bg-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                  </div>
                </div>
              </li>
            </ul>
            <dl className="tw-space-y-6 tw-border-t tw-border-gray-200 tw-px-4 tw-py-6 sm:tw-px-6">
              <div className="tw-flex tw-items-center tw-justify-between">
                <p className="tw-text-sm tw-text-gray-200 tw-bg-gray-200">Subtotal</p>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <p className="tw-text-sm tw-text-gray-200 tw-bg-gray-200">Shipping</p>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <p className="tw-text-sm tw-text-gray-200 tw-bg-gray-200">Tax</p>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 tw-pt-6">
                <p className="tw-text-base tw-font-medium tw-text-gray-200 tw-bg-gray-200">Total</p>
                <dd className="tw-text-base tw-font-medium tw-text-gray-200 tw-bg-gray-200">$123.45</dd>
              </div>
            </dl>
          </div>
        </div>
    )
  }

  if (isLoading) return <Skeleton />

  return (
  	<div>
  	  <div className="tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-shadow-sm">
  	    <ul role="list" className="tw-divide-y tw-divide-gray-200">
          {!isLoading && !isError && patientOrder && renderLineItems()}
  	    </ul>
  	    <dl className="tw-space-y-6 tw-border-t tw-border-gray-200 tw-px-4 tw-py-6 sm:tw-px-6">
  	      <div className="tw-flex tw-items-center tw-justify-between">
  	        <p className="tw-text-sm">Subtotal</p>
  	        <dd id="subtotal" className="tw-text-sm tw-font-medium tw-text-gray-900">${parseFloat(patientOrder?.sub_total).toFixed(2)}</dd>
  	      </div>
  	      <div className="tw-flex tw-items-center tw-justify-between">
  	        <p className="tw-text-sm">Shipping</p>
  	        <dd id="shipping" className="tw-text-sm tw-font-medium tw-text-gray-900">${parseFloat(patientOrder?.shipping.rate).toFixed(2)}</dd>
  	      </div>
  	      <div className="tw-flex tw-items-center tw-justify-between">
  	        <p className="tw-text-sm">Tax</p>
  	        <dd id="tax" className="tw-text-sm tw-font-medium tw-text-gray-900">${parseFloat(patientOrder?.gst).toFixed(2)}</dd>
  	      </div>
  	      <div className="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 tw-pt-6">
  	        <p className="tw-text-base tw-font-medium">Total</p>
  	        <dd id="total" className="tw-text-base tw-font-medium tw-text-gray-900">${parseFloat(patientOrder?.total).toFixed(2)}</dd>
  	      </div>
  	    </dl>
  	  </div>
  	</div>
  );
}