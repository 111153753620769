import React, {useEffect, useState} from "react"
import axios from "axios"
import AuthService from '../services/authentication'
import InfiniteScroll from "react-infinite-scroll-component";
import { useOrder } from '../data/order'

import {useFavourites} from '../data/favourites'

import ProductCard from "./ProductCard"
import ProductSearchForm from "./ProductSearchForm"
import ProductModal from "./ProductModal"

import MixtureImage from 'images/mixture_image.png'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function ProductSearchIndex(props) {

  let for_mixture = props.variant == 'mixture'
  const [products, setProducts] = useState([])
  const [searchParams, setSearchParams] = useState({query: '', category_ids: [], brands: []});
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState({})
  const [productModalShow, setProductModalShow] = useState(false)
  let auth = AuthService.useAuth()
  const {favouriteProducts, isLoading, isError} = useFavourites(auth.authHeader())
  const { order } = useOrder(auth.authHeader())

  const fetchProducts = (params = searchParams, page = page) => {
    return (
      axios.get('/api/products/search', {
        headers: auth.authHeader(),
        params: {
          ...params,
          page: page,
          for_mixture: for_mixture
        }
      })
    )
  }

  useEffect(() => {
    setPage(0)
    fetchProducts()
    .then(response => {
      setProducts(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])

  const handleShowModalClick = product => {
    setSelectedProduct(product)
    setProductModalShow(true)
  }

  const handleSearchSubmit = params => {
    setSearchParams(params)
    setPage(0)
    fetchProducts(params, 0)
    .then(response => {
      setProducts(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const fetchNextPageProducts = () => {
    const currentPage = page + 1
    fetchProducts(searchParams, currentPage)
    .then(response => {
      setPage(currentPage)
      response.data.length < 50 ? setHasMorePages(false) : ''
      setProducts(products.concat(response.data))
    })
    .catch(error => {
      console.log(error)
    })
  }

  const renderProductCards = products => {
    return products.map((product, i) => {
      return (
        <Col key={i} className="align-self-stretch">
          <ProductCard 
            product={product}
            onShowModalClick={product => handleShowModalClick(product)}
            for_mixture={for_mixture}
            mixtureId={props.mixtureId}
          />
        </Col>
      )
    })
  }

  const renderMixtureCard = () => {
    const mixtureProduct = [{
      id: 'MIXTURE',
      brand: "Integria",
      mixture: true,
      name: 'Custom Herbal Formulation',
      image_url: MixtureImage,
      description: 'Custom-made herbal tonics for your patients. Formulated by you and blended by our in-house team of qualified and experienced Naturopaths. You can rest assured the quality of your tonics are paramount to our Dispensary team. We exclusively use MediHerb liquids in your formulations as these are the global gold-standard of herbal liquid extracts and undergo rigorous quality control measures during their manufacturing process. We do not compromise on quality to ensure you and your patients get the best results. Add this product to your prescription, and customise the formulation to meet your patient’s needs.'
    }]
    return renderProductCards(mixtureProduct)
  }

  return (
    <div className=" white-bg">
    <Tabs defaultActiveKey="search" className="mb-3">
        <Tab eventKey="search" title="Search">
          <Row>
            <Col xs={12}>
              <ProductSearchForm 
                products={products}
                onSearchSubmit={handleSearchSubmit}
                for_mixture={for_mixture}
              />
            </Col>
            <Col xs={12}>
              <InfiniteScroll dataLength={Object.keys(products).length} next={fetchNextPageProducts} hasMore={hasMorePages} scrollableTarget="app-container">
                <Row xs={1} sm={2} xl={for_mixture ? 2 : 4} className="g-3 mb-3">
                    {!for_mixture && order && order.practitioner && order.practitioner.company_code != 'ihal' && renderMixtureCard()}
                    {Object.keys(products).length != 0 && renderProductCards(products)}
                </Row>
              </InfiniteScroll>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="favourites" title="Favourites">
          {!isLoading && !isError &&
            <>
            <Row xs={1} sm={2} xl={4} className="g-3 mb-3" style={{paddingBottom: '500px'}}>
              {favouriteProducts && Object.keys(favouriteProducts).length != 0 && renderProductCards(favouriteProducts, isLoading, isError)}
            </Row>
            {Object.keys(favouriteProducts, isLoading, isError).length == 0 && <h3 style={{paddingBottom: '1000px'}} className="text-muted">Favourites can be added from the product search tab..</h3>}
            </>
          }
        </Tab>
      </Tabs>
      <ProductModal
        show={productModalShow}
        onHide={() => setProductModalShow(false)}
        product={selectedProduct}
      />
      </div>
  )
}

export default ProductSearchIndex
