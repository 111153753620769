import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/authentication';
import Pagination from 'react-bootstrap/Pagination';
import usePatientList from '../data/patientList';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; 
import CreateOrderForm from '../sales_orders/CreateOrderForm'; 

function Patients() {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");
  let auth = AuthService.useAuth();
  const { patients, total_count, isLoading, isError } = usePatientList(page, submittedSearch, auth.authHeader());
  const totalPages = Math.ceil(total_count / 20);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  }

  const handleSearch = () => {
    setSubmittedSearch(search);
  }

  const formattedDate = dateString => {
    const date = new Date(dateString);
    if (!Number.isFinite(date.getTime())) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }

    const year = date.getFullYear().toString().slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {isError.message}</div>;

  return (
    <Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateOrderForm  />
        </Modal.Body>
      </Modal>
      <div className="d-flex w-50 mb-3">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={handleSearchChange}
          className="form-control me-2"
          onKeyPress={event => event.key === 'Enter' && handleSearch()}
        />
        <Button variant="secondary" onClick={handleSearch}>Search</Button>
      </div>
      <div className="d-flex justify-content-between">
        <h2 className="d-inline tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1">Patients</h2>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <span className="me-2">Create new prescription</span>
        </Button>
      </div>
      <Table borderless hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Total Spend</th>
            <th>Products Ordered</th>
            <th>Last Payment</th>
          </tr>
        </thead>
        <tbody>
          {patients.map(patient => (
            <tr key={patient.id} onClick={() => history.push(`/patient/${patient.patient_email}`)}>
              <td>{patient.patient_first_name} {patient.patient_last_name}</td>
              <td>{patient.patient_email}</td>
              <td>${patient.total_spend.toFixed(2)}</td>
              <td>{patient.total_ordered_products}</td>
              <td>{formattedDate(patient.last_payment_date)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="w-100 d-flex justify-content-between">
        <p className="ms-2 mb-0">{total_count ? `${total_count} results` : ''}</p>
        <div className="d-flex">
          <Pagination.Item onClick={() => setPage(old => Math.max(old - 1, 0))} disabled={page === 0} >Prev</Pagination.Item>
          <Pagination.Item onClick={() => setPage(old => old + 1)}  disabled={page === totalPages - 1} >Next</Pagination.Item>
        </div>
      </Pagination>
    </Container>
  );
}

export default Patients;
