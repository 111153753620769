import React from "react"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import {useFavourites, addFavourite, deleteFavourite, checkIsFavourite} from '../data/favourites'
import { useOrder, addLineItem } from '../data/order'
import { useMixture } from '../data/mixture'

import AuthService from '../services/authentication'

function ProductCard(props) {

  const product = props.product
  let auth = AuthService.useAuth()
  const {favouriteProducts, mutate} = useFavourites(auth.authHeader())
  const isFavourite = checkIsFavourite(product.id, favouriteProducts)
  const { mutate: mutateOrder } = useOrder(auth.authHeader())
  const { addLineItem: addLineItemToMixture } = useMixture(props.mixtureId, auth.authHeader())

  console.log(product)

  const onAddToOrder = async () => {
    if (props.for_mixture) {
      addLineItemToMixture(product.id)
    } else {
      await addLineItem({product_id: product.id, auth_header: auth.authHeader()})
      mutateOrder()
    }
  }

  return (
    <Card className="h-100" border={product.mixture && 'dark'}>
      <Card.Img className="p-2" variant="top" src={product.image_url} />
      <Card.Body>
        <div className="justify-content-between d-flex flex-column h-100">
          <div>
              <Card.Subtitle className="text-muted fw-light">{product.brand}</Card.Subtitle>
              <Card.Title>{product.name}</Card.Title>
          </div>
          <div className="pt-2">
            {product.price && <Card.Text className="mb-0 fw-light mt-2 d-flex justify-content-between"><span className="text-muted">Unit Price:</span><span>${product.price.toFixed(2)}{props.for_mixture && '/ml' }</span></Card.Text>}
            {product.rrp && <Card.Text className="mb-0 fw-light d-flex justify-content-between"><span className="text-muted">RRP:</span><span>${product.rrp.toFixed(2)}{props.for_mixture && '/ml' }</span></Card.Text>}
            {
              product.available_stock !== null && product.available_stock !== undefined && 
              !product.mixture && !props.for_mixture 
                ? product.available_stock > 0 
                  ? <Card.Text className="d-flex fw-light justify-content-between"><span className="text-muted">Stock:</span><span>{product.available_stock}{props.for_mixture && 'ml'}</span></Card.Text> 
                  : <Card.Text className="d-flex fw-light justify-content-between"><span className="text-muted">Stock:</span><span>Out of stock</span></Card.Text> 
                : ''
            }
            <div className="d-flex align-items-end">
              <Button className="mb-2 me-2" variant="light" onClick={() => props.onShowModalClick(product)}>Details</Button>
              {!product.mixture && 
              <>
                <Button hidden={isFavourite} className="mb-2 mt-2" variant="light" onClick={() => {
                  addFavourite({product_id: product.id, auth_header: auth.authHeader()}).then(res => mutate())
                }}>Favourite</Button>
                <Button hidden={!isFavourite} className="mb-2 mt-2" variant="light" onClick={() => {
                  deleteFavourite({product_id: product.id, auth_header: auth.authHeader()}).then(res => mutate())
                }}>Unfavourite</Button>
              </>
              }
            </div>
            <Button className="w-100 fw-bold" variant="secondary" onClick={onAddToOrder}>Select</Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductCard


