import React from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

export default function PrescriptionTable({ orders, history }) {
  const getStatusBadge = (status, sent_at) => {
    let badgeStatus;
    let badgeColor;

    if (status === 'Completed') {
      badgeStatus = 'Completed';
      badgeColor = 'secondary';
    } else if (status === 'Partial') {
      badgeStatus = 'Partial';
      badgeColor = 'secondary';
    } else if (status === 'Unsent') {
      badgeStatus = 'Unsent';
      badgeColor = 'secondary';
    } else {
      badgeStatus = status;
      badgeColor = 'secondary';
    }

    return { badgeStatus, badgeColor };
  };

  return (
    <Table borderless hover id="order-history">
      <thead>
        <tr>
          <th>Amount</th>
          <th></th>
          <th className="w-50">Description</th>
          <th>Patient</th>
          <th>Sent</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => {
          const { badgeStatus, badgeColor } = getStatusBadge(order.status, order.sent_at);

          return (
            <tr key={order.id} onClick={() => history.push(`/order/${order.id}`)}>
              <td className="price">${order.totals.patient_total.toFixed(2)}</td>
              <td><Badge className="w-100" bg={badgeColor}>{badgeStatus}</Badge></td>
              <td className="description">{order.description}</td>
              <td>{order.patient.patient_email}</td>
              <td>{order.sent_at}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
