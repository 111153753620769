import React, { useState, useEffect } from "react"
import AuthService from '../services/authentication'
import {useHistory, useLocation} from "react-router-dom"

import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid' 

import { InputField } from "../components/input"

function Login(props) {

  let query = useQuery();
  const [formValues, setFormValues] = useState({email: '', code: ''})
  const [magicLinkToken, setMagicLinkToken] = useState(query.get("token"))
  const formLabel = magicLinkToken ? 'Enter the code sent to your device' : 'Enter your Integria practitioner account email'
  let auth = AuthService.useAuth()
  let history = useHistory()
  let location = useLocation()
  const [error, setError] = useState(false)
  const [countdown, setCountdown] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const signIn = () => {
    auth.signIn(magicLinkToken)
    .then(response => {
      console.log('redirecting')
      history.replace('/')
    })
  } 

  useEffect(() => {
    if (magicLinkToken) {
      fetch(`/api/login/skip-auth?token=${magicLinkToken}`)
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(({ status, body }) => {
          console.log(body)
          if (status === 200) {
            console.log('signing in')
            signIn();
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }, [magicLinkToken]);

  const initSmsAuth = () => {
    auth.initVerifySms(magicLinkToken)
    .then(response => {
      localStorage.setItem('lastSmsInitTime', Date.now().toString()); 
      if (response.data?.number) {
        localStorage.setItem('phoneNumber', response.data.number); 
      } else {
        // signIn(); // Temporarily allow users without a phone number to sign in
      }
    });
  }

  useEffect(() => {
    const updateCountdown = () => {
      const lastSmsInitTime = parseInt(localStorage.getItem('lastSmsInitTime') || '0', 10);
      const currentTime = Date.now();
      const diff = currentTime - lastSmsInitTime;
      const remaining = 30 - Math.floor(diff / 1000); 

      if (remaining > 0) {
        setCountdown(remaining);
      } else {
        setCountdown(0);
      }
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000); 

    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    const lastSmsInitTime = parseInt(localStorage.getItem('lastSmsInitTime') || '0', 10);
    
    if (magicLinkToken && (Date.now() - lastSmsInitTime >= 30000)) {
      if(auth.authStatus == 'unauthenticated') {
        initSmsAuth()
      }
    }
  }, [magicLinkToken, auth.authStatus]);

  const handleInputChange = event => {
    setError(false)
    setSuccess(false)
    
    const target = event.target
    const value = target.value
    const name = target.name
    setFormValues({
      ...formValues,
      [name]: value
    })
   }

  const handleSubmitEmail = event => {
    setIsProcessing(true)
    event.preventDefault()
    auth.createSignInLink(formValues.email.toLowerCase())
    .then(response =>{
      setSuccess(true)
      setIsProcessing(false)
      setError(false)
    })
    .catch(error => {
      console.log(error)
      setError(true)
      setIsProcessing(false)
      setSuccess(false)
      setFormValues({email: ''})
    })
  }

  const handleSubmitCode = event => {
    setIsProcessing(true)
    event.preventDefault()
    const code = formValues.code
    auth.verifySms(magicLinkToken, code)
    .then(response =>　{
      // signIn()
      history.replace('/')
      setSuccess(true)
      setIsProcessing(false)
    })
    .catch(error => {
      console.log(error)
      setIsProcessing(false)
      setError(true)
      setFormValues({code: ''})
    })
  }

  const handleResendClick = () => {
    if (countdown === 0) {
      initSmsAuth();
    }
  };

  const Spinner = () => {
    return (
      <svg className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    )
  }

  const ErrorAlert = ({message}) => {
    return (
      <div className="tw-rounded-md tw-bg-red-50 tw-p-4">
        <div className="tw-flex">
          <div className="tw-flex-shrink-0">
            <XCircleIcon className="tw-h-5 tw-w-5 tw-text-red-400" aria-hidden="true" />
          </div>
          <div className="tw-ml-3">
            <h3 className="tw-text-sm tw-font-medium tw-text-red-800">{message}</h3>
          </div>
        </div>
      </div>
    )
  }

  const EmailSentAlert = () => {
    return (
      <div className="tw-rounded-md tw-bg-green-50 tw-p-4">
        <div className="tw-flex">
          <div className="tw-flex-shrink-0">
            <CheckCircleIcon className="tw-h-5 tw-w-5 tw-text-green-400" aria-hidden="true" />
          </div>
          <div className="tw-ml-3">
            <h3 className="tw-text-sm tw-font-medium tw-text-green-800">Email sent</h3>
            <div className="tw-mt-2 tw-text-sm tw-text-green-700">
              <p>Please check your inbox for your secure link.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SubmitButton = ({text}) => {
    return (
      <button
        type="submit"
        disabled={isProcessing || success }
        className={`tw-flex tw-justify-center tw-mt-6 tw-w-full tw-rounded-md tw-border tw-border-transparent tw-bg-integria-green tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-opacity-90 focus:tw-outline-none focus:tw-border-blue-400 disabled:tw-opacity-80 disabled:tw-pointer-events-none`} 
      >
        {success ?
          <><CheckCircleIcon className="tw-h-5 tw-w-5 tw-mr-2" />&nbsp;Success</>
        :
        isProcessing ?
          <><Spinner />&nbsp;Processing</>
        :
          text
        }
      </button>
    )
  }

  return (
    <>
      <div className="tw-flex tw-min-h-full tw-flex-1 tw-flex-col tw-justify-center tw-px-6 tw-py-12 lg:tw-px-8">
        <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-lg">
          <h2 className="tw-mt-10 tw-text-center tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
            {formLabel}
          </h2>
        </div>

        <div className="tw-mt-10 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
          {magicLinkToken ?
            <>
            {error && <ErrorAlert message="Incorrect code, please try again" />}
            <form className="tw-space-y-6" onSubmit={handleSubmitCode}>
                <div className="tw-mt-2">
                <InputField
                  label="Code"
                  onChange={handleInputChange}
                  value={formValues.code}
                  id="code"
                  name="code"
                  type="text"
                  autoComplete="one-time-code"
                />
              </div>
              <div>
                <SubmitButton text="Verify" />
              </div>
            </form>
            <div className="tw-mt-4 tw-text-center">
              {countdown > 0 ? (
                <p>Didn't receive a code? Resend in {countdown} seconds.</p>
              ) : (
                <button onClick={handleResendClick} className="tw-text-indigo-600 hover:tw-text-indigo-500 focus:tw-outline-none">
                  Resend code
                </button>
              )}
            </div>
            </>
          :
            <>
            {success ? <EmailSentAlert /> : ''}
            {error && <ErrorAlert message="Email not found." />}
            <form className="tw-space-y-6" onSubmit={handleSubmitEmail}>
                <div className="tw-mt-2">
                <InputField
                  label="Email address"
                  onChange={handleInputChange}
                  value={formValues.email}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </div>
              <div>
                <SubmitButton text="Continue" />
              </div>
            </form>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Login