import React from 'react'
import {FaqPanel} from './FaqModal'
import Logo from 'images/pop-logo.svg'

export default function FaqPage() {
  return (
    <div className="tw-pb-10">
      <img
        src={Logo}
        className="tw-object-center tw-object-contain tw-h-12 tw-mx-auto tw-mb-12"
        alt="Integria Logo"
      />
      <FaqPanel />
    </div>
  )
}