import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AuthService from '../services/authentication';
import axios from "axios";
import { Table, Badge } from 'react-bootstrap';
import MixtureImage from 'images/mixture_image.png';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DuplicateOrderForm from "../sales_orders/DuplicateOrderForm"
import {sendOrderEmail, setCurrent, deleteOrder} from '../data/order'
import useOrderHistory from '../data/orderHistory'

function OrderDetail() {
  let auth = AuthService.useAuth();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { mutateOrderHistory } = useOrderHistory(0, 'all', '', auth.authHeader());

  const handleDuplicateOrder = () => {
    setShowModal(true);
  };

  useEffect(() => {
    axios.get(`/api/orders/${id}`, {headers: auth.authHeader()})
    .then(response => {
      setOrder(response.data)
    })
    .catch(error => {
      console.log(error)
      history.replace('/');
    })
  }, [])

  if (!order) return <div>Loading...</div>;

  const handleResendOrderEmail = () => {
    sendOrderEmail({order_id: order.id, auth_header: auth.authHeader()})
      .then(response => {
        alert('Order email has been resent successfully.');
      })
      .catch(error => {
        console.log(error);
        alert('Could not resend that order. Please reload the page and try again.');
      });
  }

  const handleDeleteOrder = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this order?");
    if (confirmDelete) {
      deleteOrder({order_id: order.id, auth_header: auth.authHeader()})
        .then(() => {
          mutateOrderHistory()
          history.push('/prescriptions');
        })
        .catch(error => {
          console.error("Error deleting order:", error);
        });
    }
  };

  const totals = order.totals || {};

  const renderProductTable = () => (
    <>
    <h4 className="pb-2 tw-text-2xl tw-tracking-tight tw-text-gray-900 tw-pb-1 tw-pt-4">Products</h4>
    <Table borderless>
      <thead>
        <tr>
          <th></th>
          <th>Product</th>
          <th>Available</th>
          <th>Quantity</th>
          <th>Ordered</th>
          <th>Price</th>
          <th>Markup</th>
          <th>Patient Price</th>
        </tr>
      </thead>
      <tbody>
        {order.product_line_items.map((item, index) => (
          <tr key={index}>
            <td><img src={item.image_url} style={{width: '25px', height: '45px', objectFit: 'contain', objectPosition: 'center bottom'}} /></td>
            <td>{item.name}</td>
            <td>{item.active ? 'Yes' : 'No'}</td>
            <td>{item.quantity}</td>
            <td>{item.ordered_quantity}</td>
            <td>${item.practitioner_price.toFixed(2)}</td>
            <td>{item.use_rrp ? 'RRP' : item.markup + '%'}</td>
            <td>${item.patient_price.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );

  const renderMixtureTable = () => (
    <>
    <h4 className="pb-2 tw-text-2xl tw-tracking-tight tw-text-gray-900 tw-pb-1">Mixtures</h4>
    <Table borderless>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Weight</th>
          <th>Quantity</th>
          <th>Ordered</th>
          <th>Patient Price</th>
        </tr>
      </thead>
      <tbody>
        {order.mixture_line_items.map((mixture, index) => (
          <React.Fragment key={`mixture-${index}`}>
            <tr className="mixture">
              <td><img src={MixtureImage} alt={mixture.name} style={{width: '25px'}} /></td>
              <td>{mixture.name}</td>
              <td>{mixture.max_weight}ml</td>
              <td>{mixture.quantity}</td>
              <td>{mixture.ordered_quantity}</td>
              <td>${mixture.patient_price.toFixed(2)}</td>
            </tr>
            {mixture.herbs.map((herb, herbIndex) => (
              <tr key={`mixture-${index}-herb-${herbIndex}`} className="mixture-herb">
                <td></td>
                <td>{herb.name}</td>
                <td></td>
                <td>{herb.quantity}ml</td>
                <td></td>
                <td></td>
              </tr> 
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
    </>
  );

  return (
    <Container>
      {/*<a href="#" className="text-muted" onClick={() => history.goBack()}>{'<'} Back</a><br/>*/}
      <Badge bg={order.status === 'Completed' ? 'success' : (order.status === 'Partial' ? 'primary' : 'secondary')}>{order.status}</Badge>
      <h2 className="mt-2 tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1">Prescription</h2>
      <p>{order.sent_at}<br/>
      {order.patient.patient_first_name} {order.patient.patient_last_name}<br/>
      {order.patient.patient_email}<br/>
      {order.patient.patient_phone}</p> 
      {Object.keys(order.product_line_items).length ? renderProductTable() : ''}
      {Object.keys(order.mixture_line_items).length ? renderMixtureTable() : ''}
      <div className="d-flex w-100 justify-content-start">
        <div className="d-flex flex-column align-items-start pe-4">
          <strong>Subtotal:</strong>
          <strong>Markup:</strong>
          <strong>Tax:</strong>
          <strong>Total:</strong>
        </div>
        <div className="d-flex flex-column align-items-end">
          <span>${totals.practitioner_total.toFixed(2)}</span>
          <span>${totals.profit.toFixed(2)}</span>
          <span>${totals.gst.toFixed(2)}</span>
          <span>${totals.patient_total.toFixed(2)}</span>
        </div>
      </div>
      <div className="mt-3">
        {order.status !== 'Completed' && <Button variant="secondary" className="me-2" onClick={handleResendOrderEmail}>Resend Email</Button>}
        {/*{order.status == 'Unsent' && <Button className="me-2" onClick={() => setCurrent({order_id: order.id, auth_header: auth.authHeader()}).then(res => history.push('/order/edit'))}>Edit and send</Button>}*/}
        <Button variant="secondary" className="me-2" onClick={handleDuplicateOrder}>Duplicate</Button>
        <Button variant="danger" onClick={handleDeleteOrder}>Delete</Button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DuplicateOrderForm orderID={order.id} patient={order.patient} />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default OrderDetail;
