import React, { useState } from "react"
import { useParams } from "react-router-dom"
import AuthService from '../services/authentication'
import { useMixture } from '../data/mixture'
import ProductSearchIndex from "../products/ProductSearchIndex"
import MixtureSummary from "./MixtureSummary"
import MaxProductsModal from "./MaxProductsModal"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function MixtureIndex() {
	const auth = AuthService.useAuth()
	const { id } = useParams()
	const [displayMaxProductsPopup, setDisplayMaxProductsPopup] = useState(false)
	const { mixture, isLoading, isError, addLineItem } = useMixture(id, auth.authHeader())

	if (isLoading) return <div>Loading...</div>
	if (isError) return <div>Error loading mixture</div>

	const onAddToOrder = product => {
		if (mixture.sales_order_items.length < 10) {
			addLineItem(product)
		} else {
			setDisplayMaxProductsPopup(true)
		}
	}

	return (
		<>
		<Row>
		  <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
		    <ProductSearchIndex 
         onOrderAddition={onAddToOrder}
         variant="mixture"
				 mixtureId={id}
		    />
		  </Col>
		  <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}} className="mb-4">
		    <MixtureSummary id={id} />
		  </Col>
		</Row>
		<MaxProductsModal 
			show={displayMaxProductsPopup} 
			onHide={() => setDisplayMaxProductsPopup(false)} 
		/>
		</>
	)
}

export default MixtureIndex