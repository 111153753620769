import { useOrder, createOrder } from '../data/order'
import AuthService from '../services/authentication';
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

function CreateOrderForm(props) {
  let auth = AuthService.useAuth();
  let history = useHistory()
  const [formValues, setFormValues] = useState({email: '', firstName: '', lastName: '', phone: ''})

  const { order, mutate } = useOrder(auth.authHeader()); 

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await createOrder({
        patient: {
          ...formValues,
        },
        auth_header: auth.authHeader()
      });
      mutate()
      history.push("/order/edit");
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-2">
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Col xs={6}>
          <Form.Control name="firstName" required onChange={handleInputChange} type="text" placeholder="Patient first name" />
        </Col>
        <Col xs={6}>
          <Form.Control name="lastName" required onChange={handleInputChange} type="text" placeholder="Patient last name" />
        </Col>
        <Col xs={12}>
            <Form.Control name="email" required onChange={handleInputChange} type="email" placeholder="Patient email address" />
        </Col>
        <Col xs={12}>
            <Form.Control name="phone" required onChange={handleInputChange} type="text" placeholder="Patient phone number" />
        </Col>
        <Col xs={6}>
          <Button variant="primary" className="mrt-4" type="submit">Next</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default CreateOrderForm
