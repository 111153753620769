import React, { useEffect, useState } from "react"
import Logo from 'images/integria-logo-dark.png'
import { useParams } from "react-router-dom"
import MixtureImage from 'images/mixture_image.png'
import { ChatBubbleOvalLeftEllipsisIcon, EnvelopeIcon } from '@heroicons/react/24/solid'

export default function TransactionSummaryPage() {
  let { id } = useParams()
  const [transaction, setTransaction] = useState(null)
  console.log(transaction)

  useEffect(() => {
    document.documentElement.style.setProperty('--body-bg-color', 'white');
  }, [])

  useEffect(() => {
    fetch(`/api/transactions/summary/${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch transaction')
        }
        return response.json()
      })
      .then(data => {
        console.log(data)
        setTransaction(data)
      })
  }, [id])

  if (!transaction) {
    return <div>Loading...</div>
  }

  return (
    <div className="tw-bg-white ">
      <Header />
      <div className="tw-mx-auto tw-max-w-6xl tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-mb-24">
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10">
          <div>
            <OrderConfirmation transaction={transaction} />
            <CustomerInformation transaction={transaction} />
          </div>
          <div>
            <OrderItems transaction={transaction} />
            <OrderSummary transaction={transaction} />
          </div>
        </div>
        <Footer transaction={transaction} />
      </div>
    </div>
  )
}

function Header() {
  return (
    <div className="tw-flex tw-justify-center">
      <img
        src={Logo}
        className="tw-object-center tw-object-contain tw-h-20 tw-mb-20"
        alt="Integria Logo"
      />
    </div>
  )
}

function OrderConfirmation({ transaction }) {
  return (
    <div className="tw-max-w-xl tw-border-b tw-border-gray-200 tw-pb-6">
      {/* <h1 className="tw-text-base tw-font-medium ">Payment Successful</h1>
      <p className="tw-mt-2 tw-text-4xl tw-font-bold tw-tracking-tight sm:tw-text-5xl">Thanks for ordering</p> */}
      <p className="tw-text-base tw-text-integria-green">
        Hi {transaction.patient.first_name},<br />
        Thank you for your order #{transaction.id}. A confirmation e-mail will be sent to {transaction.patient_email} shortly.<br /><br />
        Once your order is shipped, we will send you an email with tracking details. Please note that Liquid Mixtures are shipped separately.<br /><br />
        Feel free to visit the main <a href="/faq" className="tw-underline">frequently asked questions</a> page if you have any queries or need more information.
      </p>
    </div>
  )
}

function Footer({ transaction }) {

  const company_code = transaction.practitioner.company_code
  const contact_email = company_code == 'inzl' ? 'ordersnz@integria.com' : 'orders@integria.com'
  const contact_phone = company_code == 'inzl' ? '0800 48 33 66' : '1300 654 336'
  const live_chat_url = company_code == 'inzl' ? 'https://nz.integria.com' : 'https://au.integria.com'


  return (
    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
      <div className="">
        <a className="tw-flex tw-flex-nowrap tw-items-center tw-gap-2" href={`mailto:${contact_email}`}>
          <EnvelopeIcon className="tw-h-12 tw-w-12 tw-text-gray-500" />
          <span className="tw-text-gray-500">Contact us</span>
        </a>
      </div>
      <div className="tw-flex tw-flex-nowrap tw-items-center tw-gap-2">
        <a className="tw-flex tw-flex-nowrap tw-items-center tw-gap-2" href={live_chat_url} target="_blank">
          <ChatBubbleOvalLeftEllipsisIcon className="tw-h-12 tw-w-12 tw-text-gray-500" />
          <span className="tw-text-gray-500">Live Chat</span>
        </a>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">
        <span className="tw-font-bold tw-text-gray-500">{contact_phone}</span>
        <span>Contact us</span>
      </div>
    </div>
  )
}

function OrderItems({ transaction }) {
  return (
    <div>
      <h2 className="tw-mb-4">Order Summary</h2>
      {[
        ...transaction.transaction_items.filter(item => !item.mixture_transaction_item_id),
        ...transaction.mixture_transaction_items
      ].map((item) => (
        <OrderItem key={item.id} item={item} />
      ))}
    </div>
  )
}

function OrderItem({ item }) {
  const isMixture = item.transaction_items;
  const subtext = isMixture
    ? item.transaction_items
      .map(child => `${child.product_name.split(' 1:')[0]} ${child.quantity}ml`)
      .join(', ')
    : '';

  const image_url = isMixture ? MixtureImage : item.image_url;

  return (
    <div className="tw-flex tw-space-x-6 tw-border-b tw-border-gray-200 tw-py-10">
      <img
        src={image_url}
        alt={item.product_name}
        className="tw-h-20 tw-w-20 tw-flex-none tw-rounded-lg tw-bg-gray-100 tw-object-contain tw-object-center tw-p-2"
      />
      <div className="tw-flex tw-flex-auto tw-flex-col">
        <div>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <h4 className="tw-font-medium tw-text-gray-900">{item.product_name || item.mixture.name}</h4>
            {isMixture && (
              <span className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-600">
                Herbal Formulation
              </span>
            )}
          </div>
          <p className="tw-mt-2 tw-text-sm tw-text-gray-600">{subtext}</p>
        </div>
        <div className="tw-mt-6 tw-flex tw-flex-1 tw-items-end">
          <dl className="tw-flex tw-space-x-4 tw-divide-x tw-divide-gray-200 tw-text-sm sm:tw-space-x-6">
            <div className="tw-flex">
              <dt className="tw-font-medium tw-text-gray-900">Quantity</dt>
              <dd className="tw-ml-2 tw-text-gray-700">{item.quantity}</dd>
            </div>
            <div className="tw-flex tw-pl-4 sm:tw-pl-6">
              <dt className="tw-font-medium tw-text-gray-900">Price</dt>
              <dd className="tw-ml-2 tw-text-gray-700">${parseFloat(item.patient_price).toFixed(2)}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

function CustomerInformation({ transaction }) {
  return (
    <div>
      <h3 className="tw-sr-only">Your information</h3>
      <AddressInformation transaction={transaction} />
    </div>
  )
}

function AddressInformation({ transaction }) {
  const { patient } = transaction;
  
  const formatAddress = (address) => {
    return [
      address.address,
      address.suburb,
      `${address.city}${address.postcode ? `, ${address.postcode}` : ''}`,
      address.country
    ].filter(Boolean).join(', ');
  };

  const billingAddress = patient.billing_address || patient.shipping_address;

  return (
    <div>
      <dl className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-py-10 tw-text-sm">
        <div>
          <dt className="tw-font-medium tw-text-gray-900">Delivery address</dt>
          <dd className="tw-mt-2 tw-text-gray-700">
            <address className="tw-not-italic">
              <span className="tw-block">{patient.first_name} {patient.last_name}</span>
              <span className="tw-block">{formatAddress(patient.shipping_address)}</span>
              {patient.shipping_address.business && (
                <span className="tw-block">{patient.shipping_address.business}</span>
              )}
            </address>
          </dd>
        </div>
        <div>
          <dt className="tw-font-medium tw-text-gray-900">Billing address</dt>
          <dd className="tw-mt-2 tw-text-gray-700">
            <address className="tw-not-italic">
              <span className="tw-block">{patient.first_name} {patient.last_name}</span>
              <span className="tw-block">{formatAddress(billingAddress)}</span>
              {billingAddress.business && (
                <span className="tw-block">{billingAddress.business}</span>
              )}
            </address>
          </dd>
        </div>
      </dl>
      <dl className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-py-10 tw-text-sm">
        <div>
          <dt className="tw-font-medium tw-text-gray-900">Payment details</dt>
          <dd className="tw-mt-2 tw-text-gray-700">
            <span className="tw-block">Credit/Debit card</span>
          </dd>
        </div>
        <div>
          <dt className="tw-font-medium tw-text-gray-900">Practitioner details</dt>
          <dd className="tw-mt-2 tw-text-gray-700">
            <span className="tw-block">{transaction.practitioner.name}</span>
            <span className="tw-block">{transaction.practitioner.email}</span>
          </dd>
        </div>
      </dl>
    </div>
  )
}

function OrderSummary({ transaction }) {
  const taxRate = parseFloat(transaction.tax_rate);
  const paymentAmount = parseFloat(transaction.payment_amount);
  const shippingPrice = parseFloat(transaction.shipping_price);
  const subtotal = paymentAmount / (1 + taxRate) - shippingPrice;
  const taxAmount = paymentAmount - subtotal;

  return (
    <div className="sm:tw-ml-40 sm:tw-pl-6">
      <h3 className="tw-sr-only">Summary</h3>
      <dl className="tw-space-y-6 tw-border-t tw-border-gray-200 tw-pt-10 tw-text-sm">
        <div className="tw-flex tw-justify-between">
          <dt className="tw-font-medium tw-text-gray-900">Subtotal</dt>
          <dd className="tw-text-gray-700">${subtotal.toFixed(2)}</dd>
        </div>
        <div className="tw-flex tw-justify-between">
          <dt className="tw-flex tw-font-medium tw-text-gray-900">Shipping</dt>
          <dd className="tw-text-gray-700">${shippingPrice.toFixed(2)}</dd>
        </div>
        <div className="tw-flex tw-justify-between">
          <dt className="tw-font-medium tw-text-gray-900">Tax ({(taxRate * 100).toFixed(0)}%)</dt>
          <dd className="tw-text-gray-700">${taxAmount.toFixed(2)}</dd>
        </div>
        <div className="tw-flex tw-justify-between">
          <dt className="tw-font-medium tw-text-gray-900">Total</dt>
          <dd className="tw-text-gray-900">${paymentAmount.toFixed(2)}</dd>
        </div>
      </dl>
    </div>
  )
}
