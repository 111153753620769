import React, { useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";
import AuthService from './services/authentication';

export default function PrivateRoute({component: Component, ...rest}) {
  const auth = AuthService.useAuth()
  
  return (
    <Route
      {...rest}
      render={(props) => {
        auth?.checkAuthStatus()
        if (auth.authStatus === 'pending') {
          return <div>Loading...</div>;
        } else if (auth.authStatus === 'authenticated') {
          return <Component {...props} />;
        } else {
          return <Redirect to={{
            pathname: "/login",
            state: { from: props.location }
          }} />;
        }
      }}
    />
  );
}
