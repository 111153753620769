import React from 'react'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ProgressBar from 'react-bootstrap/ProgressBar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Card from 'react-bootstrap/Card'

function MixtureHeader({ mixture, currentWeight, onNameChange, onMaxWeightUpdate }) {
  const weights = [200, 500]

  return (
    <Card.Header>
      <h4 className="mb-0">Formulation Details</h4>
      <Form.Label className="mt-3 text-muted">1. Name your Formula</Form.Label>
      <Form.Control 
        value={mixture.name || ''} 
        onChange={e => onNameChange(e.target.value)} 
        name="name" 
        placeholder="e.g. Sleep Tonic" 
        className="mb-2" 
      />
      <Form.Label className="w-100 mt-3 text-muted">2. Select your bottle size</Form.Label>
      <ButtonGroup>
        {weights.map((weight, i) => (
          <ToggleButton 
            key={i} 
            onClick={() => onMaxWeightUpdate(weight)} 
            type="radio" 
            style={{opacity: mixture.max_weight != weight ? 0.5 : 1 }} 
            value={weight} 
            checked={mixture.max_weight == weight} 
            variant='outline-dark' 
            name="radio"
          >
            {weight}ml
          </ToggleButton> 
        ))}
      </ButtonGroup>
      <ProgressBar max={mixture.max_weight} className="my-2">
        {mixture.sales_order_items.length != 0 &&
          mixture.sales_order_items.map(lineItem => (
            <OverlayTrigger key={lineItem.id} placement="bottom" delay={{ show: 100, hide: 250 }} overlay={<Tooltip>{lineItem.name} - {lineItem.quantity}ml</Tooltip>}>
              <ProgressBar isChild={true} max={mixture.max_weight} style={{ borderRight: '1px solid #e9ecef'}} now={lineItem.quantity} />
            </OverlayTrigger>
          ))
        }
      </ProgressBar>
      <p className="text-muted">{currentWeight}ml/{mixture.max_weight}ml</p>
      <Form.Label className="text-muted">3. Choose your ingredients</Form.Label>
    </Card.Header>
  )
}

export default MixtureHeader