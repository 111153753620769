import React from 'react'
import Form from 'react-bootstrap/Form'

function MixtureInstructions({ mixture, onNoteChange }) {
  return (
    <>
      <Form.Label className="text-muted">4. Patient instructions</Form.Label>
      <Form.Control 
        value={mixture.note || ''} 
        onChange={e => onNoteChange(e.target.value)} 
        name="notes" 
        as="textarea" 
        placeholder="Dosage Notes.." 
        className="mb-2" 
      />
    </>
  )
}

export default MixtureInstructions