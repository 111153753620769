import React from "react"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function ProductModal(props) {
	const product = props.product

	const RenderModal = () => {
		return (
			<Modal {...props} size="lg" centered scrollable>
				<Modal.Header closeButton>
					<Modal.Title><div className="text-muted">{product.brand}</div> {product.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="d-flex justify-content-center">
						<Image className="w-50 mb-3" thumbnail fluid src={product.image_url} />
						<div dangerouslySetInnerHTML={{__html: product.description}} />
					</Row>
				</Modal.Body>
		    </Modal>
		)
	}

	return (
		Object.keys(product).length ? RenderModal() : ''
	)
}

export default ProductModal