import axios from "axios";
import useSWR from 'swr';

const orderKey = `/api/practitioner/current-order`;

const orderFetcher = ([url, auth_header]) => axios.get(url, {headers: auth_header}).then(res => res.data)

function createOrder({patient, auth_header}) {
  return axios.post(`/api/orders/create`, {patient: {...patient}}, {headers: auth_header});
}

function setCurrent({order_id, auth_header}) {
  return axios.post(`/api/orders/set-current`, {id: order_id}, {headers: auth_header});
}

function deleteOrder({order_id, auth_header}) {
  return axios.post(`/api/orders/delete`, {id: order_id}, {headers: auth_header});
}

function duplicateOrder({id, patient, auth_header}) {
  return axios.post(`/api/orders/duplicate`, {id: id, patient: {...patient}}, {headers: auth_header});
}

function sendOrderEmail({order_id, auth_header}) {
  return axios.post(`/api/orders/send`, {id: order_id}, {headers: auth_header});
}

function addLineItem({product_id, auth_header}) {
  return axios.post(`/api/orders/add-line-item`, {product_id: product_id}, {headers: auth_header})
}

function removeLineItem({line_item_id}) {
  return axios.delete(`/api/practitioner/remove-line-item`, {data: {line_item_id}});
}

function updateLineItem({line_item_id, property, value, auth_header, mixture=false}) {
  return axios.post(`/api/orders/update-line-item`, {line_item_id, property, value, mixture}, {headers: auth_header});
}

function sendOrder({order_id}) {
  return axios.post(`/api/practitioner/send-order`, {order_id});
}

function useOrder(auth_header) {
  const {data, error, mutate} = useSWR([orderKey, auth_header], orderFetcher);

  return {
    order: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate
  };
}

export {
  useOrder,
  createOrder,
  duplicateOrder,
  sendOrderEmail,
  addLineItem,
  removeLineItem,
  updateLineItem,
  sendOrder,
  setCurrent,
  deleteOrder
};
