import React, { useState, useEffect } from "react"
import axios from "axios"
import AuthService from '../services/authentication'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Select from 'react-select';


function ProductSearchForm(props) {


  const [formValues, setFormValues] = useState({query: '', category_ids: [], brands: []});
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  let auth = AuthService.useAuth();

  useEffect(() => {
    fetchCategoryOptions();
    fetchBrandOptions();
  }, [])

  const fetchCategoryOptions = () => {
    axios.get(`/api/products/categories`, {headers: auth.authHeader()})
    .then(response => {
      setCategoryOptions(formatCategoryOptions(response.data))
    })
    .catch(error => {
      console.log(error);
    })
  }

  const fetchBrandOptions = () => {
    axios.get(`/api/products/brands`, {headers: auth.authHeader()})
    .then(response => {
      setBrandOptions(formatBrandOptions(response.data))
    })
    .catch(error => {
      console.log(error);
    })
  }

  const formatCategoryOptions = categories => {
    let formattedCategories = [];
    let formattedCategory = {};
    categories.map(category => {
      const subCategories = category.hasOwnProperty('subcategories') && category['subcategories'].length ? formatCategoryOptions(category['subcategories']) : [];
      formattedCategory = {
        label: category['name'],
        value: category['id'],
        options: subCategories
      }
      formattedCategories.push(formattedCategory)
    })
    return formattedCategories
  }

  const formatBrandOptions = brands => {
    let formattedBrands = [];
    let formattedBrand = {};
    brands.map(brand => {
      formattedBrand = {
        label: brand,
        value: brand,
      }
      formattedBrands.push(formattedBrand)
    })
    return formattedBrands
  }

  const handleCategoriesSelectChange = selectedCategories => {
    let categoryIds = []
    selectedCategories.map(category => {
      categoryIds.push(category['value'])
    })
    setFormValues({
      ...formValues,
      category_ids: categoryIds
    })
  }

  const handleBrandsSelectChange = selectedBrands => {
    let brands = []
    selectedBrands.map(brand => {
      brands.push(brand['value'])
    })
    setFormValues({
      ...formValues,
      brands: brands
    })
  }

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.onSearchSubmit(formValues)
  }

  const renderCategoriesSelect = () => {
    return (
      <Select
        onChange={handleCategoriesSelectChange}
        name="categories"
        closeMenuOnSelect={false}
        isMulti
        options={categoryOptions}
        placeholder="Select Categories.."
      />
    )
  }

  const renderBrandsSelect = () => {
    return (
      <Select
        onChange={handleBrandsSelectChange}
        name="brands"
        closeMenuOnSelect={false}
        isMulti
        options={brandOptions}
        placeholder="Select Brands.."
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <Row className="g-2">
        <Col sm={12}>
          <Form.Control name="query" type="text" placeholder="Search products..." onChange={handleInputChange} value={formValues.name}/>
        </Col>
        <Col sm={12} xl={6}>
          {brandOptions.length != 0 && !props.for_mixture && renderBrandsSelect()}
        </Col>
        <Col sm={12} xl={6}>
          {categoryOptions.length != 0 && !props.for_mixture && renderCategoriesSelect()}
        </Col>
        <Col sm={3}>
          <Button variant="secondary" type="submit">Search</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ProductSearchForm