import React, {useEffect} from 'react';
import { BrowserRouter as Router } from "react-router-dom"
import AuthService from './services/authentication'
import { gtmInit } from './data/gtm';
import "tailwindcss/tailwind.css" //Doesn't work on production 

import Header from "./layout/header"
import Routes from './Routes';

import Container from 'react-bootstrap/Container'

function App() {

  useEffect(() => {
    gtmInit()
  }, [])

  return (
    <AuthService.ProvideAuth>
      <Router>
        <Header />
        <Container fluid={'xl'} id="app-container" className={`app-container p-4 tw-font-inter`}>
          <div className="main-wrapper"> 
            <Routes />
          </div>
        </Container>
      </Router>
    </AuthService.ProvideAuth>
  )
}

export default App
