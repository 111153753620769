import React, { useState } from "react";
import { useMixture } from "../data/mixture";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import AuthService from "../services/authentication";

function MixtureLineItems({ mixture }) {
  const auth = AuthService.useAuth();
  const { updateLineItem, deleteLineItem } = useMixture(
    mixture.id,
    auth.authHeader()
  );
  const [markups, setMarkups] = useState({});
  const [blankQuantities, setBlankQuantities] = useState(new Set());

  const handleLineItemChange = (lineItem, key, value) => {
    updateLineItem(lineItem.id, key, value);
  };

  const handleQuantityChange = (lineItem, quantity, allow_zero = false) => {
    if (quantity === "") {
      setBlankQuantities(prev => new Set(prev).add(lineItem.id));
    } else {
      setBlankQuantities(prev => {
        const newSet = new Set(prev);
        newSet.delete(lineItem.id);
        return newSet;
      });
      if (quantity <= mixture.max_weight || allow_zero) {
        handleLineItemChange(lineItem, "quantity", parseInt(quantity));
      }
    }
  };

  const handleRemoveLineItem = (lineItem) => {
    deleteLineItem(lineItem.id);
  };

  const handleMarkupChange = (lineItem, value) => {
    if (value !== "") {
      value = Math.max(0, Math.min(300, value));
    }
    setMarkups((prevMarkups) => ({ ...prevMarkups, [lineItem.id]: value }));
    if (value !== "" && value >= 0 && value <= 300) {
      handleLineItemChange(lineItem, "markup", value);
    }
  };

  const renderMarkupForm = (lineItem) => {
    const markup = markups[lineItem.id] ?? lineItem.markup ?? "";

    return (
      <InputGroup
        className="mt-2"
        style={lineItem.use_rrp ? { opacity: 0.2, pointerEvents: "none" } : {}}
      >
        <Form.Label className="w-100 text-muted mb-1">Markup:</Form.Label>
        <Form.Control
          name="markup"
          type="number"
          style={{ maxWidth: "7em" }}
          placeholder="0"
          onChange={(e) => handleMarkupChange(lineItem, e.target.value)}
          value={markup}
          min={0}
          step={0.01}
        />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    );
  };

  const quantityForm = (lineItem) => {
    return (
      <Form className="d-inline-flex align-items-center">
        <InputGroup>
          <Form.Label className="w-100 text-muted mb-1">Quantity:</Form.Label>
          <FormControl
            style={{ maxWidth: "7em" }}
            type="number"
            value={blankQuantities.has(lineItem.id) ? "" : (lineItem.quantity || "")}
            onBlur={(e) =>
              handleQuantityChange(lineItem, e.target.value, false)
            }
            onChange={(e) =>
              handleQuantityChange(lineItem, e.target.value, true)
            }
          />
          <InputGroup.Text>ml</InputGroup.Text>
          <Button
            variant="danger"
            onClick={(e) => handleRemoveLineItem(lineItem)}
          >
            Remove
          </Button>
        </InputGroup>
      </Form>
    );
  };

  const renderPricingOptions = (lineItem) => {
    return (
      <Form className="mt-2">
        <Form.Label className="text-muted me-2 mb-0">Pricing:</Form.Label>
        <Form.Check
          onChange={(e) =>
            handleLineItemChange(lineItem, "use_rrp", !lineItem.use_rrp)
          }
          type="radio"
          checked={lineItem.use_rrp}
          label="RRP"
        />
        <Form.Check
          onChange={(e) =>
            handleLineItemChange(lineItem, "use_rrp", !lineItem.use_rrp)
          }
          type="radio"
          checked={!lineItem.use_rrp}
          label="Markup"
        />
      </Form>
    );
  };

  return (
    <ListGroup variant="flush">
      {mixture.sales_order_items.map((lineItem) => (
        <ListGroup.Item key={lineItem.id} className="mt-1">
          <Row>
            <Col xs={9}>
              <p className="w-100 fw-bold">{lineItem.product.name}</p>
              <div>
                {quantityForm(lineItem)}
                {renderPricingOptions(lineItem)}
                {renderMarkupForm(lineItem)}
              </div>
            </Col>
            <Col className="d-flex align-content-end justify-content-end ">
              <p className="fw-bold">
                ${(lineItem.patient_price * lineItem.quantity).toFixed(2)}
              </p>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
      <ListGroup.Item key="dispensing-charge" className="mt-1">
        <Row>
          <Col xs={9}>
            <p className="w-100 mb-0">Dispensing Charge</p>
          </Col>
          <Col className="d-flex align-content-end justify-content-end ">
            <p className="mb-0">$9.77</p>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
}

export default MixtureLineItems;
