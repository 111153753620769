import React from 'react';
import { Route, Switch, Redirect, useLocation  } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import AuthService from './services/authentication';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Login from "./sessions/login"
import ProductSearchIndex from "./products/ProductSearchIndex"
import OrderSummary from "./sales_orders/OrderSummary"
import OrderIndex from "./sales_orders/OrderIndex"
import OrderDetail from "./sales_orders/OrderDetail"
import PractitionerIndex from "./practitioners/PractitionerIndex"
import Prescriptions from "./practitionersNew/Prescriptions"
import PaymentSuccess from "./patients/PaymentSuccess"
import MixtureIndex from "./mixtures/MixtureIndex"
import Patients from "./practitionersNew/Patients"; 
import PatientDetail from "./practitionersNew/PatientDetail"; 
import Checkout from "./checkout/PatientCheckout"; 
import TransactionSummaryPage from "./checkout/TransactionSummary";
import Transactions from "./transactions/Transactions";
import FaqPage from "./checkout/FaqPage";

function LoginRoute(props) {
  const auth = AuthService.useAuth()

  if (auth.authStatus === 'authenticated') {
    return <Redirect to="/" />;
  }

  return <Login {...props} />;
}

export default function Routes(props) {
  const location = useLocation();
  
  return (
    <Switch>
      <Route path="/login" component={LoginRoute} />
      <Route path="/patient/order">
        <Redirect to={`/checkout${location.search}`} />
      </Route>
      <Route exact path="/checkout" component={Checkout} />
      <Route path="/payment-success" component={PaymentSuccess} />
      <Route path="/faq" component={FaqPage} />
      <Route path="/transaction/:id" component={TransactionSummaryPage} />
      <PrivateRoute exact path="/order" component={OrderIndex} />
      <PrivateRoute exact path="/order/mixtures/:id/edit" component={MixtureIndex} />
      <PrivateRoute exact path="/order/edit">
        <Row>
          <Col xs={{span: 12, order: 2}} md={{span: 8, order: 1}} xl={9}>
            <ProductSearchIndex />
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 4, order: 2}} xl={3} className="mb-4">
            <OrderSummary/>
          </Col>
        </Row> 
      </PrivateRoute>
      <PrivateRoute exact path="/transactions" component={Transactions} />
      <PrivateRoute path="/order/:id" component={OrderDetail} />
      <PrivateRoute exact path="/" component={PractitionerIndex} />
      <PrivateRoute exact path="/prescriptions" component={Prescriptions} />
      <PrivateRoute exact path="/patients" component={Patients} />
      <PrivateRoute exact path="/patient/:email" component={PatientDetail} />
    </Switch>
  )
}
