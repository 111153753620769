import React, { useState, useEffect } from "react"
import { useMixture } from '../data/mixture'
import AuthService from '../services/authentication'
import { useHistory } from "react-router-dom"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import MixtureHeader from './MixtureHeader'
import MixtureLineItems from './MixtureLineItems'
import MixtureInstructions from './MixtureInstructions'

function MixtureSummary({ id }) {
  const auth = AuthService.useAuth()
  const { mixture, isLoading, isError, updateProperty } = useMixture(id, auth.authHeader())
  const [currentWeight, setCurrentWeight] = useState(0)
  const [showCapacityAlert, setShowCapacityAlert] = useState(false)
  const [showNameAlert, setShowNameAlert] = useState(true)
  const history = useHistory()

  useEffect(() => {
    if (mixture) {
      calcCurrentWeight()
      mixture.name ? setShowNameAlert(false) : setShowNameAlert(true)
    }
  }, [mixture])

  useEffect(() => {
    if (mixture) {
      setShowCapacityAlert(currentWeight > mixture.max_weight)
    }
  }, [currentWeight, mixture?.max_weight])

  const calcCurrentWeight = () => {
    if (mixture?.sales_order_items) {
      const total = mixture.sales_order_items.reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0)
      setCurrentWeight(total)
    }
  }

  const handleNameChange = (value) => {
    updateProperty('name', value)
    setShowNameAlert(!value)
  }

  const saveOrder = () => {
    if (!showCapacityAlert && !showNameAlert) {
      history.push('/order')
    } else {
      console.log("Cannot save order due to capacity or name issues")
    }
  }

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error loading mixture</div>

  return (
    <Card>
      <MixtureHeader 
        mixture={mixture}
        currentWeight={currentWeight}
        onNameChange={handleNameChange}
        onMaxWeightUpdate={(weight) => updateProperty('max_weight', weight)}
      />
      <MixtureLineItems mixture={mixture} />
      <Card.Body>
        <MixtureInstructions 
          mixture={mixture}
          onNoteChange={(value) => updateProperty('note', value)}
        />
        <Row>
          <Col xs={12} className="mt-4">
            {mixture.patient_price > 0 &&
              <>
                <h4>Patient Total: ${mixture.patient_price.toFixed(2)}*</h4>
                <p>*Tax Inclusive</p>
              </> 
            }
          </Col>
          <Col xs={12}>
            {showCapacityAlert &&
              <Alert variant="danger">
                <p className="mb-0">
                  Capacity exceeded. Please remove {currentWeight - mixture.max_weight}ml before saving.
                </p>
              </Alert>
            }
            {showNameAlert &&
              <Alert variant="danger">
                <p className="mb-0">
                  Please set a name for this formulation before saving.
                </p>
              </Alert>
            }
            <Button 
              variant="primary" 
              className="mt-2" 
              onClick={saveOrder} 
              disabled={mixture.sales_order_items.length == 0 || currentWeight > mixture.max_weight || !mixture.name}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default MixtureSummary
