import axios from "axios";
import useSWR from 'swr';

const mixtureFetcher = ([url, auth_header]) => axios.get(url, {headers: auth_header}).then(res => res.data);

function useMixture(mixture_id, auth_header) {
  const mixtureKey = `/api/mixtures/${mixture_id}`;
  const { data, error, mutate } = useSWR([mixtureKey, auth_header], mixtureFetcher);

  const updateProperty = async (property, value) => {
    const optimisticData = { ...data, [property]: value };
    
    try {
      await mutate(
        axios.post(`/api/mixtures/${mixture_id}`, { [property]: value }, { headers: auth_header }).then(res => res.data),
        {
          optimisticData,
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }
      );
    } catch (error) {
      console.error("Failed to update property:", error);
    }
  };

  const addLineItem = async (product_id) => {

    try {
      await mutate(
        axios.post(`/api/mixtures/${mixture_id}/add-line-item`, { product_id }, { headers: auth_header }).then(res => res.data),
        {
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }
      );
    } catch (error) {
      console.error("Failed to add line item:", error);
    }
  };

  const updateLineItem = async (line_item_id, key, value) => {
    console.log(data)
    const optimisticData = {
      ...data,
      sales_order_items: data.sales_order_items.map(item =>
        item.id === line_item_id ? { ...item, [key]: value } : item
      )
    };

    try {
      await mutate(
        axios.post(`/api/mixtures/${mixture_id}/update-line-item/${line_item_id}`, {[key]: value }, { headers: auth_header }).then(res => res.data),
        {
          optimisticData,
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }
      );
    } catch (error) {
      console.error("Failed to update line item:", error);
    }
  };

  const deleteLineItem = async (line_item_id) => {
    const optimisticData = {
      ...data,
      sales_order_items: data.sales_order_items.filter(item => item.id !== line_item_id)
    };

    try {
      await mutate(
        axios.delete(`/api/mixtures/${mixture_id}/delete-line-item/${line_item_id}`, { headers: auth_header }).then(res => res.data),
        {
          optimisticData,
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }
      );
    } catch (error) {
      console.error("Failed to delete line item:", error);
    }
  };

  return {
    mixture: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    updateProperty,
    addLineItem,
    updateLineItem,
    deleteLineItem
  };
}

export {
  useMixture,
};