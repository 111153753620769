import React, {useState} from "react"
import AuthService from '../services/authentication'
import {useHistory} from "react-router-dom"

import CreateOrderForm from "../sales_orders/CreateOrderForm"
import SetMarkupForm from "./SetMarkupForm"
import SetRrpForm from "./setRrpForm"

import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

function PractitionerIndex(props) {

	const [showModal, setShowModal] = useState(false);

	let auth = AuthService.useAuth()
	let history = useHistory()

	const signOut = () => {
		auth.signOut()
		.then(() => history.push("/login"))
	}

	return (
		<>
		<Modal show={showModal} onHide={() => setShowModal(false)}>
		  <Modal.Header closeButton>
		    <Modal.Title>Create prescription</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
		    <CreateOrderForm  />
		  </Modal.Body>
		</Modal>
		<div className="d-flex justify-content-between">
		  <Button variant="primary" onClick={() => setShowModal(true)}>
		   	<span className="me-2">Create new prescription</span>
		  </Button>
		</div>
		<Row className="mt-4 mb-4" >
			<Col xs={12} lg={12} className="d-flex flex-column align-items-start mb-4">
				<Col xs={12}>
					{auth.user?.user && 
						<div className="d-flex d-flex-column">
							<p className="pt-4 pb-3">Signed in as: {auth.user.user.email}</p>
							<p onClick={signOut} className="text-muted ms-2 pt-4" style={{cursor: 'pointer'}}>Logout</p>
						</div>
					}
				</Col>
				<Col xs={12} lg={6} className="mb-4">
					<SetRrpForm />
				</Col>
				<Col xs={12} lg={3}>
					<SetMarkupForm />
				</Col>
				<Col xs={12} className="tw-mt-8 tw-underline">
					<a href="/practitioner-terms.pdf" target="_blank" rel="noopener noreferrer">
						View Our Terms and Conditions
					</a>
				</Col>
			</Col>
		</Row>
		<hr />
		</>
	)
}

export default PractitionerIndex