import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/16/solid';
import React, { useState } from 'react';

const Tooltip = ({ text }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="tw-relative tw-inline-block tw-z-10">
      <div
        className="tw-cursor-pointer"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={() => setVisible(!visible)}
      >
        <QuestionMarkCircleIcon className="tw-w-4 tw-h-4 tw-text-gray-300" />
      </div>
      {visible && (
        <div className="tw-absolute tw-bottom-full tw-mb-2 tw-w-56 tw--translate-x-1/2 tw-p-2 tw-bg-gray-700 tw-text-white tw-text-sm tw-rounded tw-shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;