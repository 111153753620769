import React from "react"
import AuthService from '../services/authentication'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {useOrder} from '../data/order'

import OrderDetail from "./PractitionerOrderDetail"

function OrderIndex(props) {

  let auth = AuthService.useAuth()
  const {order, isLoading, isError, mutate} = useOrder(auth.authHeader())

  if(isLoading) return <div>Loading...</div>;
  if(isError) return <div>Error occurred!</div>;

	return (
		<>
			<h2 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1">Review prescription</h2>
			<p>{order.patient.patient_email && `Prescription for ${order.patient.patient_email}`}</p>
			{props.currentOrderSent && <p className="fw-bold">This script has been sent.</p>}
			<Row className="mb-4">
				<Col xs={12} md={9}>
					<OrderDetail />
	      </Col>
			</Row>
		</>
	)
}

export default OrderIndex