import React from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

export default function TransactionTable({ transactions, history }) {

  console.log(transactions)

  return (
    <Table borderless hover id="transaction-history">
      <thead>
        <tr>
          {/* <th>Patient</th> */}
          <th>Email</th>
          <th>Items</th>
          <th>Amount</th>
          <th>Rebate</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => {

          return (
            <tr key={transaction.id} >
              {/* <td> {transaction.patient ? `${transaction.patient.first_name} ${transaction.patient.last_name}` : ''} </td> */}
              <td>{transaction.patient_email}</td>
              <td>{transaction.item_count}</td>
              <td className="price">${transaction.payment_amount?.toFixed(2)}</td>
              <td className="price">{transaction.rebate ? `$${transaction.rebate.toFixed(2)}` : 'Processing'}</td>
              <td>{transaction.created_at}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}