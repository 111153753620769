import React, {useState} from "react"
import axios from "axios"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PatientOrderSummary from "../sales_orders/PatientOrderSummary"

function PatientIndex() {
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)

  const createPaymentSession = () => {
    setIsLoadingCheckout(true)
    axios.post("/api/patient/create-stripe-session", {token: token})
    .then(response => window.location.href = response.data.url)
    .catch(error => setIsLoadingCheckout(false))
  }

  return (
    <Row>
      <Col xs={12}>
        <PatientOrderSummary token={token} onPaymentContinue={createPaymentSession} isLoadingCheckout={isLoadingCheckout} />
      </Col>
    </Row>
  )
}

export default PatientIndex




