import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

function LoadingComponent() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 100; // Each time progress increases by 100%
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 1); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="my-3">
      <ProgressBar animated now={progress} />
    </div>
  );
}

export default LoadingComponent;
