import React from "react";
import AuthService from '../services/authentication'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Logo from 'images/pop-logo.svg'
import {Link, useLocation} from "react-router-dom";
import {useOrder} from '../data/order'
import AlertBanner from '../practitioners/AlertBanner';
import Notification from "../components/notification"

function Header(props) {

	let auth = AuthService.useAuth()
	const location = useLocation();

	if (location.pathname.includes('/checkout') || location.pathname.includes('/transaction/') || location.pathname.includes('/faq') ) return <></>
	const isPatientPage = location.pathname.includes('/checkout') || location.pathname.includes('/payment-success') || location.pathname.includes('/login')

	let {order} = useOrder(auth.authHeader())

	return (
		<>
		<Navbar className="mb-2 w-100"> 
				<Navbar.Brand>
					<img
						width="210"
						height="52"
						src={Logo}
						style={{ objectFit: 'contain'}}
						className="d-inline-block align-top"
						alt="Integria Logo"
					/>
				</Navbar.Brand>
				<Navbar.Toggle />
				{auth.user && !location.pathname.includes('/patient/order') &&
					<Navbar.Collapse className="justify-content-start">
						<Nav variant="pills" defaultActiveKey="/new">
							<Nav.Link active={location.pathname == '/'} as={Link} to="/">Home</Nav.Link>
							<Nav.Link active={location.pathname == '/prescriptions'} as={Link} to="/prescriptions" >Prescriptions</Nav.Link>
							<Nav.Link active={location.pathname == '/patients'} as={Link} to="/patients" >Patients</Nav.Link>
							<Nav.Link active={location.pathname == '/transactions'} as={Link} to="/transactions" >Transactions</Nav.Link>
							{order && <Nav.Link active={location.pathname == '/order' || location.pathname == '/order/edit'} as={Link} to="/order" >Current Order</Nav.Link>}
						</Nav>
					</Navbar.Collapse>
				}
		</Navbar>
		{ !isPatientPage && 	
			<Notification 
			  cookieName="Sprint4PracNotification"
			  title="New Features!"
			  content={"Added a new page 'Transactions' to view patient transactions and associated rebates"}
			 />
		}
		{ !isPatientPage && !auth.user?.user?.phone_number &&
			<div className="container-xl">
		    <AlertBanner />
	    </div>
		}
		</>
	)
} 

export default Header