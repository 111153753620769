import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import React from 'react'

export default function AlertBanner() {
  return (
    <div className="tw-rounded-md tw-bg-red-50 tw-p-4 tw-mb-4">
      <div className="tw-flex">
        <div className="tw-flex-shrink-0">
          <ExclamationTriangleIcon className="tw-h-5 tw-w-5 tw-text-red-800" aria-hidden="true" />
        </div>
        <div className="tw-ml-3">
          <h3 className="tw-text-sm tw-font-medium tw-text-yellow-800">Attention needed</h3>
          <div className="tw-mt-2 tw-text-sm tw-text-red-700">
            <p>
              Integria Healthcare has added an extra security step, known as "Multi-Factor Authentication" (MFA), to keep your account safe. To use this new feature, we need your mobile phone number on file, which we currently don't have. Please call our Accounts team at 0800 553 556 to update your account.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

