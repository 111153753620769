import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../services/authentication';
import PrescriptionTable from './PrescriptionTable'; 
import LoadingComponent from '../helpers/LoadingComponent';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';

function PatientDetail() {
  const { email } = useParams();
  const [page, setPage] = useState(0);
  const [patient, setPatient] = useState(null);
  const [orders, setOrders] = useState([]);
  const [total_count, setTotalCount] = useState(0); 
  const history = useHistory();
  let auth = AuthService.useAuth();

  useEffect(() => {
    axios.get(`/api/practitioner/patient/${email}?page=${page}`, {headers: auth.authHeader()})
      .then(response => {
        console.log(response);
        setPatient(response.data.patient);
        setOrders(response.data.orders);
        setTotalCount(response.data.total_count); 
      })
      .catch(error => {
        console.log(error);
      })
  }, [email, page]);
 
  const totalPages = Math.ceil(total_count / 20); 

  if (!patient || !orders) return <LoadingComponent />;

  return (
    <Container>
      <h2 className='tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1'>{patient.patient_first_name} {patient.patient_last_name}</h2>
      <p>{patient.patient_email}</p>
      <PrescriptionTable orders={orders} history={history} />
      <Pagination className="w-100 d-flex justify-content-between"> {/* ADD THIS */}
        <p className="ms-2 mb-0">{total_count ? `${total_count} results` : ''}</p>
        <div className="d-flex">
          <Pagination.Item onClick={() => setPage(old => Math.max(old - 1, 0))} disabled={page === 0} >Prev</Pagination.Item>
          <Pagination.Item onClick={() => setPage(old => old + 1)}  disabled={page === totalPages - 1} >Next</Pagination.Item>
        </div>
      </Pagination>
    </Container>
  );
}

export default PatientDetail;
