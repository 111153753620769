import React, { useEffect } from "react"
import CheckoutForm from "./CheckoutForm"
import OrderSummary from "./OrderSummary"
import {usePatientOrder} from '../data/patientOrder'
import Logo from 'images/pop-logo.svg'
import Notification from "../components/notification"
import FaqModal from "./FaqModal"

export default function PatientCheckout() {

  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const {patientOrder, isLoading, isError, mutate} = usePatientOrder(token)

  useEffect(() => {
    document.documentElement.style.setProperty('--body-bg-color', '#f9fafb');
  }, [])

  return (
    <>
      <div className="tw-mx-auto tw-max-w-7xl tw-pb-8">
        <div className="tw-flex tw-justify-between tw-flex-wrap">
          <p className="tw-text-3xl tw-font-bold tw-tracking-tight tw-integria-green tw-pb-1 tw-order-2 sm:tw-order-1">Your Prescription</p>
          <img
            src={Logo}
            className="tw-object-right tw-object-contain tw-h-8 sm:tw-h-12 tw-order-1 sm:tw-order-2 tw-mb-3 sm:tw-mb-0"
            alt="Integria Logo"
          />
        </div>
        <div className="tw-mt-2 tw-border-b tw-border-gray-200 tw-pb-2 tw-text-sm tw-flex tw-justify-between tw-flex-col sm:tw-flex-row">
          <div className={`tw-flex ${isLoading ? 'tw-invisible' : ''}`}>
            <div className="tw-mr-2 tw-hidden sm:tw-block">
                <p className="tw-text-gray-500 tw-mb-1">Practitioner</p>
                <p className="tw-text-gray-500">Patient</p>
            </div>
            <div>
              <div className="tw-flex tw-mb-1">
                <p className="tw-font-medium tw-text-gray-900">{patientOrder?.practitioner?.first_name}&nbsp;{patientOrder?.practitioner?.last_name}</p>
                <p>
                  <span className={`tw-mx-2 tw-text-gray-400`}>
                    &middot;
                  </span>
                </p>
                <a href={`mailto:${patientOrder?.practitioner.email}`} className="tw-font-medium">
                  <p>{patientOrder?.practitioner.email}&nbsp;</p>
                </a>
              </div>
              <p className="tw-font-medium tw-text-gray-900">{patientOrder?.patient.first_name}&nbsp;{patientOrder?.patient.last_name}</p>
            </div>
          </div>
          <p className="tw-font-medium tw-text-gray-900 tw-flex tw-items-end mt-2 sm:mt-0">
            <time dateTime={patientOrder?.created_at}>{patientOrder?.created_at}&nbsp;</time>
          </p>
        </div>
      </div>
      <main className="lg:tw-min-h-full tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-12 xl:tw-gap-x-16">
      	<div>
		      <OrderSummary token={token} />
          <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-4">
            <FaqModal />
            <a href="/patient-terms.pdf" target="_blank" rel="noopener noreferrer" className="tw-group tw-inline-flex tw-text-sm tw-text-gray-500 hover:tw-text-gray-700 tw-cursor-pointer">
              Terms and Conditions
            </a>
          </div>
      	</div>
      	<div>
	        <CheckoutForm token={token} />
        </div>
      </main>
      <Notification 
        cookieName="Sprint5Notification"
        title="Important Updates"
        content={"We've added Terms & Conditions and changed our email to noreply@patient.integria.com.\nPlease update your safe sender list."}
       />
    </>
  )
}
