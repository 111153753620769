import React, {useEffect, useState} from "react"
import axios from "axios"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';

import MixtureImage from 'images/mixture_image.png'
 
import {usePatientOrder, updateLineItemQuantity, addRetailProduct, optimisticLineItemUpdate, optimisticLineItemAddition} from '../data/patientOrder'

function PatientOrderSummary(props) {

  const [retailAddOns, setRetailAddOns] = useState([])
  const {patientOrder, isloading, isError, mutate} = usePatientOrder(props.token)

  useEffect(() => {
    axios.get('/api/products/retail-add-ons')
    .then(response => {
      if(response.data) {
        setRetailAddOns(response.data)
      } 
    })
    .catch(error => {
      console.log(error)
    })
  }, []) 

  const handleQuanityChange = (line_item_id, quantity, is_mixture) => {
    mutate(updateLineItemQuantity({token: props.token, line_item_id: line_item_id, quantity: quantity, is_mixture: is_mixture}), {
      optimisticData: patientOrder => optimisticLineItemUpdate(patientOrder, line_item_id, 'ordering_quantity', quantity),
      populateCache: false,
    })
  }

  const handleAddRetailProduct = product => {
    mutate(addRetailProduct({token: props.token, product_id: product.id}), {
      optimisticData: patientOrder => optimisticLineItemAddition(patientOrder, product),
      populateCache: false,
    })
  }

  const quantityForm = lineItem => {
    return (
      <Form className="d-inline-flex align-items-start">
        <InputGroup>
          <Button disabled={lineItem.ordering_quantity == 0} variant="outline-secondary" onClick={() => handleQuanityChange(lineItem.id, lineItem.ordering_quantity - 1, lineItem.mixture)}>-</Button>
          <FormControl style={{maxWidth: '3rem', textAlign: 'center'}} value={lineItem.max_q > 0 ? lineItem.ordering_quantity : 0} readOnly />
          <Button disabled={lineItem.ordering_quantity >= lineItem.max_q} variant="outline-secondary" onClick={() => handleQuanityChange(lineItem.id, lineItem.ordering_quantity + 1, lineItem.mixture)}>+</Button>
        </InputGroup>
      </Form>
    )
  }

  const renderExtraProductsModal = () => {
    return (
      <>
        <h4 className="pt-3">You may also like …</h4>
        <Row className="g-2">
          {retailAddOns.length && retailAddOns.map(product => { 
            return(
              <Col xs={6} sm={3} md={2}>
                <Card className="h-100">
                  <Card.Img className="p-2" style={{height: '7em'}} variant="top" src={product.image_url} />
                  <Card.Body>
                    <div className="justify-content-between d-flex flex-column h-100">
                      <div>
                          <Card.Subtitle className="text-muted fw-light">{product.brand}</Card.Subtitle>
                          <Card.Title>{product.name}</Card.Title>
                      </div>
                      <div className="pt-2">
                        {!product.available_stock && <Badge bg="light" text="dark" className="mt-2">Out of stock</Badge>}
                        {product.price && <Card.Text className="mb-0 fw-light mt-2 d-flex justify-content-between"><span className="text-muted">Unit Price:</span><span>${product.price.toFixed(2)}{props.for_mixture && '/ml' }</span></Card.Text>}
                        {product.rrp && <Card.Text className="mb-0 fw-light d-flex justify-content-between"><span className="text-muted">Price:</span><span>${product.rrp.toFixed(2)}{props.for_mixture && '/ml' }</span></Card.Text>}
                        <Button disabled={!product.available_stock} className="w-100 fw-bold" variant="secondary" onClick={() => handleAddRetailProduct(product)}>Add</Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      </>
    )
  }

  const calcPrice = lineItem => `$${(lineItem.patient_price * lineItem.ordering_quantity).toFixed(2)}`

  const renderLineItems = () => {
    return patientOrder.line_items && patientOrder.line_items.map((lineItem, i) => {
      return renderLineItem(lineItem)
    })
  }

  const renderLineItem = lineItem => {
    return (
      <Row key={lineItem.id} className="py-2 bg-bottom-grey g-0">
        <Col xs={3} md={1} className="d-flex justify-content-start align-items-center">
          <Image src={lineItem.mixture ? MixtureImage : lineItem.image_url} style={{height: '100%', width: '65px', border: 'none', aspectRatio: '1/1', objectFit: 'contain', objectPosition: 'top'}} />
        </Col>
        <Col xs={9} md={11}>
          <Row>
            <Col xs={12} md={8}>
              <Row className="d-flex align-content-between h-100">
                <Col xs={12}>
                  <h5>{lineItem.name}</h5>
                  {lineItem.mixture && lineItem.herbs && <p className="mb-0">{lineItem.herbs}</p>}
                  {lineItem.note && <p>{lineItem.note}</p>}
                </Col>
              </Row>
            </Col>
            <Col xs={6} md={2} className="d-flex align-content-start flex-row justify-content-between align-items-center pb-2">
              <Row xs={1}>
                <Col>{quantityForm(lineItem)}</Col>
                <Col>{lineItem.fully_ordered && <Badge bg="light" text="dark" className="mt-2">Fully ordered</Badge>}</Col>
                <Col>{!lineItem.fully_ordered && !lineItem.max_q && <Badge bg="light" text="dark" className="mt-2">Out of stock</Badge>}</Col>
                <Col>{lineItem.max_q > 0 && !lineItem.retail_add_on ? <Badge style={{cursor: 'pointer'}} onClick={() => handleQuanityChange(lineItem.id, lineItem.max_q, lineItem.mixture)} bg="light" text="dark" className="mt-2">Max: {lineItem.max_q}</Badge> : ''}</Col>
              </Row>
            </Col>
            <Col xs={6} md={2} className="d-flex align-content-between justify-content-end pb-2">
              <h5>{calcPrice(lineItem)}</h5>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const renderPage = () => {
    return (
      <>
        <div className="d-flex justify-content-between pb-3 bg-bottom-grey"></div>
        {renderLineItems()}
        <Row className="mt-4">
          <Col className="d-flex justify-content-between flex-wrap">
            <div className="d-flex justify-content-start flex-wrap">
              <div className="me-2">
                <p className="w-100 mb-0 text-muted tw-text-3xl">Patient:</p>
                <p className="w-100 mb-0 text-muted">Practitioner:</p>
                <p className="w-100 mb-0 text-muted">Created:</p>
              </div>
              <div>
                <p className="mb-0">{patientOrder.patient.patient_first_name} {patientOrder.patient.patient_last_name}</p>
                <a style={{color: 'black'}} href={patientOrder.practitioner.email}><p className="mb-0">{patientOrder.practitioner.first_name} {patientOrder.practitioner.last_name}</p></a>
                <p className="mb-0">{patientOrder.created_at}</p>
              </div>
            </div>
            <div>
              <h4 className="text-end fw-bold">${patientOrder.total && patientOrder.total.toFixed(2)}</h4>
              <Button variant="primary" disabled={patientOrder.total <= 0} hidden={props.isLoadingCheckout} className="mt-2" onClick={() => props.onPaymentContinue()}>Continue to payment</Button>
              <Button variant="primary" disabled className="mt-2" hidden={!props.isLoadingCheckout}>Loading checkout<Spinner as="span" className="ms-1" animation="border" role="status" size="sm"/></Button>
            </div>
          </Col>
        </Row>
        {/*{renderExtraProductsModal()}*/}
        <div className="text-muted">
          <p className=" mb-0">For more information on these products, please consult your prescribing health practitioner.</p>
          <p className="">This prescription is valid for 6 months or until all products have been purchased.</p>
          <p className="mb-0">
            You may order all or part of this prescription, and the remaining products can be ordered at a later date (within 6 months of issue).
          </p>
          <p className="mb-2">
            Please only order what you require, as Integria has a strict no-returns/refunds policy.
          </p>
        </div>
      </>
    )
  }

  return (
    patientOrder && Object.keys(patientOrder.line_items).length != 0 ? renderPage() : <h1>Loading your order...</h1>
    
  )
}

export default PatientOrderSummary
