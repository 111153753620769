import axios from "axios"
import useSWR from 'swr'
var _ = require('lodash')

const key = `/api/patient/order`

const fetcher = ([url, token]) => axios.get(url, {params: {token:token}}).then(res => res.data)

function addRetailProduct({token, product_id}) {
  return axios.post(`/api/patient/retail-add-on`, {token: token, product_id: product_id})
}

async function updateLineItemQuantity({patientOrder, token, line_item_id, quantity, is_mixture}) {
  const res = await axios.post(`/api/patient/update-quantity`,{token: token, line_item_id: line_item_id, quantity: quantity, mixture: is_mixture})
  return res.data
}

function optimisticLineItemUpdate(patientOrder, id, key, value) {
  let patientOrderClone = _.clone(patientOrder)
  let line_item = _.find(patientOrderClone.line_items, ['id', id])
  _.set(line_item, [key], value)
  _.set(patientOrderClone, ['mutated'], new Date().getTime()) //SWR bug workaround
  return patientOrderClone
}

function optimisticLineItemAddition(patientOrder, product) {
  let patientOrderClone = _.clone(patientOrder)
  patientOrderClone.line_items.push({image_url: product.image_url, name: product.name, patient_price: product.rrp, ordering_quantity: 1, retail_add_on: true, max_q: 10})
  _.set(patientOrderClone, ['mutated'], new Date().getTime()) //SWR bug workaround
  return patientOrderClone
}

function usePatientOrder(token) {
  const {data, error, isValidating, mutate} = useSWR([key, token], fetcher)

  return {
    patientOrder: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate
  }
}

export {
  usePatientOrder,
  updateLineItemQuantity,
  addRetailProduct,
  optimisticLineItemUpdate,
  optimisticLineItemAddition
}
