import React, { useState } from 'react';
import useTransactionHistory, { downloadTransactionCSV } from '../data/transactionHistory';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import AuthService from '../services/authentication'
import { useHistory } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import LoadingComponent from '../helpers/LoadingComponent';
import TransactionTable from './TransactionTable';

function Transactions() {
  const [page, setPage] = useState(0);
  const [key, setKey] = useState('all');
  const [search, setSearch] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submittedStartDate, setSubmittedStartDate] = useState("");
  const [submittedEndDate, setSubmittedEndDate] = useState("");
  let auth = AuthService.useAuth()
  const { transactions, total_count, isLoading, isError, mutateTransactionHistory } = useTransactionHistory(page, key, submittedSearch, submittedStartDate, submittedEndDate, auth.authHeader());
  const history = useHistory()
  const totalPages = Math.ceil(total_count / 20);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  }

  const handleDownload = async () => {
    const result = await downloadTransactionCSV(auth.authHeader(), startDate, endDate);
    if (result.success) {
      alert('Your report has been sent to your email.');
    } else {
      alert('Something went wrong. Please try again.');
    }
  }

  const handleSearchSubmit = () => {
    setSubmittedSearch(search);
    setSubmittedStartDate(startDate);
    setSubmittedEndDate(endDate);
    mutateTransactionHistory(); 
  }

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-1">Transactions</h2>
      </div>
      <div className="d-flex mb-3">
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <Button onClick={handleSearchSubmit}>Search</Button>
      </div>
      {isLoading ? (
        <LoadingComponent />
      ) : isError ? (
        <div>Error: {isError.message}</div>
      ) : (
        <>
          <Button variant="primary" onClick={handleDownload}>Download</Button>
          <TransactionTable transactions={transactions} history={history} />
          <Pagination className="w-100 d-flex justify-content-between">
            <p className="ms-2 mb-0">{`${total_count} results`}</p>
            <div className="d-flex">
              <Pagination.Item onClick={() => setPage(old => Math.max(old - 1, 0))} disabled={page === 0} >Prev</Pagination.Item>
              <Pagination.Item onClick={() => setPage(old => old + 1)} disabled={page === totalPages - 1} >Next</Pagination.Item>
            </div>
          </Pagination>
        </>
      )}
    </Container>
  );
}

export default Transactions;