import useSWR from 'swr';
import axios from 'axios';

const fetcher = ([url, auth_header]) => axios.get(url, {headers: auth_header}).then(res => res.data)

export default function useOrderHistory(page, status, search, auth_header) {
  const { data, error, isValidating, mutate } = useSWR([`/api/practitioner/orders?page=${page}&status=${status}&search=${search}`, auth_header], fetcher);

  return {
    orders: data ? data.orders : null,
    total_count: data ? data.total_count : null,
    isLoading: !error && !data,
    isError: error,
    mutateOrderHistory: mutate
  };
}
